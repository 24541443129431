import React, { useState, useMemo, useEffect } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Select, MenuItem, FormControl, InputLabel, IconButton, Grid, Button, SelectChangeEvent, Tooltip, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FormattedMessage, useIntl } from 'react-intl';
import { Expense } from '../types/Expense';
import { FaEdit, FaTrash, FaSort, FaSortUp, FaSortDown, FaExchangeAlt, FaTag, FaSearch, FaCalendarAlt, FaEuroSign, FaUser, FaSave, FaTimes, FaUpload, FaFileExport } from 'react-icons/fa';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import CustomChip from './CustomChip';

interface FilteredExpensesProps {
  expenses: Expense[];
  filter: {
    year: number;
    month: number | 'all';
    payer: string;
  };
  payers: string[];
  categories: string[];
  onEditExpense: (expense: Expense, file?: File) => Promise<void>;
  onDeleteExpense: (id: string) => void;
  payerColors: { [key: string]: string };
}

type SortableExpenseKey = keyof Omit<Expense, 'id' | 'fileUrl'> | 'type' | 'sharedWithOrDebtFrom';

interface Column {
  id: SortableExpenseKey | 'actions' | 'receipt';
  label: React.ReactNode;
  render: (expense: Expense, isEditing: boolean, editingExpense: Expense | null, handleEditChange: any, handleSharedChange: any) => React.ReactNode;
}

type SortConfig = {
  key: SortableExpenseKey;
  direction: 'asc' | 'desc';
} | null;

const FilteredExpenses: React.FC<FilteredExpensesProps> = ({ 
  expenses, 
  filter, 
  payers, 
  categories, 
  onEditExpense, 
  onDeleteExpense, 
  payerColors 
}) => {
  const intl = useIntl();
  const [selectedPayer, setSelectedPayer] = useState<string>('');
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [descriptionFilter, setDescriptionFilter] = useState<string>('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [editingExpense, setEditingExpense] = useState<Expense | null>(null);
  const [sortConfig, setSortConfig] = useState<SortConfig>(null);

  const [openReceiptDialog, setOpenReceiptDialog] = useState(false);
  const [selectedReceipt, setSelectedReceipt] = useState('');

  const [editingFile, setEditingFile] = useState<File | null>(null);

  const filteredExpenses = useMemo(() => {
    return expenses.filter(expense => {
      const dateMatch = new Date(expense.date).getFullYear() === filter.year &&
                        (filter.month === 'all' || new Date(expense.date).getMonth() === filter.month);
      const descriptionMatch = expense.description.toLowerCase().includes(descriptionFilter.toLowerCase());
      const payerMatch = filter.payer === '' || expense.payer === filter.payer;
      const categoryMatch = selectedCategory === '' || expense.category === selectedCategory;
      return dateMatch && descriptionMatch && payerMatch && categoryMatch;
    });
  }, [expenses, filter, descriptionFilter, selectedCategory]);

  const totalExpenses = useMemo(() => {
    return filteredExpenses.reduce((sum, expense) => sum + expense.amount, 0);
  }, [filteredExpenses]);

  const handleEdit = (expense: Expense) => {
    setEditingExpense({ ...expense });
    setEditingFile(null);
  };

  const handleSaveEdit = async () => {
    if (editingExpense) {
      try {
        await onEditExpense(editingExpense, editingFile || undefined);
        setEditingExpense(null);
        setEditingFile(null);
      } catch (error) {
        console.error("Error saving edited expense:", error);
        // Você pode adicionar uma notificação de erro aqui
      }
    }
  };

  const handleCancelEdit = () => {
    setEditingExpense(null);
  };

  const handleDelete = (id: string) => {
    if (window.confirm(intl.formatMessage({ id: 'delete' }))) {
      onDeleteExpense(id);
    }
  };

  const handleEditChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
    if (editingExpense) {
      const { name, value } = e.target;
      setEditingExpense(prev => {
        if (!prev) return null;
        return {
          ...prev,
          [name]: name === 'amount' ? Number(value) : value,
        };
      });
    }
  };

  const handleSharedChange = (e: SelectChangeEvent<string>) => {
    if (editingExpense) {
      setEditingExpense(prev => {
        if (!prev) return null;
        return {
          ...prev,
          shared: e.target.value === 'yes',
        };
      });
    }
  };

  const sortedExpenses = useMemo(() => {
    let sortableExpenses = [...filteredExpenses];
    if (sortConfig !== null) {
      sortableExpenses.sort((a, b) => {
        let aValue: any;
        let bValue: any;

        if (sortConfig.key === 'type') {
          aValue = a.isDebt ? 'debt' : a.shared ? 'shared' : '-';
          bValue = b.isDebt ? 'debt' : b.shared ? 'shared' : '-';
        } else if (sortConfig.key === 'sharedWithOrDebtFrom') {
          aValue = a.shared ? a.sharedWith : a.isDebt ? a.debtFrom : '-';
          bValue = b.shared ? b.sharedWith : b.isDebt ? b.debtFrom : '-';
        } else {
          aValue = a[sortConfig.key as keyof Expense];
          bValue = b[sortConfig.key as keyof Expense];
        }

        if (aValue === null || aValue === undefined) return 1;
        if (bValue === null || bValue === undefined) return -1;

        if (typeof aValue === 'string') {
          aValue = aValue.toLowerCase();
        }
        if (typeof bValue === 'string') {
          bValue = bValue.toLowerCase();
        }

        if (aValue < bValue) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableExpenses;
  }, [filteredExpenses, sortConfig]);

  const requestSort = (key: SortableExpenseKey) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const [columns, setColumns] = useState<Column[]>([]);

  const handleOpenReceipt = (fileUrl: string) => {
    setSelectedReceipt(fileUrl);
    setOpenReceiptDialog(true);
  };

  const handleCloseReceipt = () => {
    setOpenReceiptDialog(false);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setEditingFile(event.target.files[0]);
    }
  };

  const exportToCSV = () => {
    const headers = ['Categoria', 'Descrição', 'Data', 'Montante', 'Pagador', 'Tipo', 'Partilhada Com / Dívida De'];
    const csvContent = [
      '\uFEFF' + headers.join(';'), // BOM for UTF-8
      ...sortedExpenses.map(expense => [
        `"${expense.category.replace(/"/g, '""')}"`,
        `"${expense.description.replace(/"/g, '""')}"`,
        new Date(expense.date).toLocaleDateString(),
        `"€${expense.amount.toFixed(2)}"`,
        `"${expense.payer.replace(/"/g, '""')}"`,
        `"${expense.isDebt ? 'Dívida' : expense.shared ? 'Partilhada' : '-'}"`,
        `"${(expense.shared && expense.sharedWith ? expense.sharedWith : expense.isDebt && expense.debtFrom ? expense.debtFrom : '-').replace(/"/g, '""')}"`
      ].join(';'))
    ].join('\r\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'despesas_filtradas.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  // Ordena as categorias alfabeticamente
  const sortedCategories = useMemo(() => {
    return [...categories].sort((a, b) => a.localeCompare(b));
  }, [categories]);

  useEffect(() => {
    setColumns([
      {
        id: 'category',
        label: (
          <Box display="flex" alignItems="center">
            <FaTag style={{ marginRight: '8px' }} />
            <FormattedMessage id="category" />
          </Box>
        ),
        render: (expense, isEditing, editingExpense, handleEditChange) => (
          isEditing ? (
            <Select
              value={editingExpense?.category || ''}
              onChange={handleEditChange}
              name="category"
              fullWidth
            >
              {categories.map(cat => (
                <MenuItem key={cat} value={cat}>{cat}</MenuItem>
              ))}
            </Select>
          ) : (
            expense.category
          )
        ),
      },
      {
        id: 'description',
        label: (
          <Box display="flex" alignItems="center">
            <FaSearch style={{ marginRight: '8px' }} />
            <FormattedMessage id="description" />
          </Box>
        ),
        render: (expense, isEditing, editingExpense, handleEditChange) => (
          isEditing ? (
            <TextField
              value={editingExpense?.description || ''}
              onChange={handleEditChange}
              name="description"
              fullWidth
            />
          ) : (
            expense.description
          )
        ),
      },
      {
        id: 'date',
        label: (
          <Box display="flex" alignItems="center">
            <FaCalendarAlt style={{ marginRight: '8px' }} />
            <FormattedMessage id="date" />
          </Box>
        ),
        render: (expense, isEditing, editingExpense, handleEditChange) => (
          isEditing ? (
            <TextField
              type="date"
              value={editingExpense?.date || ''}
              onChange={handleEditChange}
              name="date"
              fullWidth
            />
          ) : (
            new Date(expense.date).toLocaleDateString()
          )
        ),
      },
      {
        id: 'amount',
        label: (
          <Box display="flex" alignItems="center">
            <FaEuroSign style={{ marginRight: '8px' }} />
            <FormattedMessage id="amount" />
          </Box>
        ),
        render: (expense, isEditing, editingExpense, handleEditChange) => (
          isEditing ? (
            <TextField
              type="number"
              value={editingExpense?.amount || ''}
              onChange={handleEditChange}
              name="amount"
              fullWidth
            />
          ) : (
            `€${expense.amount.toFixed(2)}`
          )
        ),
      },
      {
        id: 'payer',
        label: (
          <Box display="flex" alignItems="center">
            <FaUser style={{ marginRight: '8px' }} />
            <FormattedMessage id="payer" />
          </Box>
        ),
        render: (expense, isEditing, editingExpense, handleEditChange) => (
          isEditing ? (
            <Select
              value={editingExpense?.payer || ''}
              onChange={handleEditChange}
              name="payer"
              fullWidth
            >
              {payers.map(payer => (
                <MenuItem key={payer} value={payer}>{payer}</MenuItem>
              ))}
            </Select>
          ) : (
            <CustomChip
              label={expense.payer}
              color={payerColors[expense.payer] || '#E0E0E0'}
              size="small"
            />
          )
        ),
      },
      {
        id: 'type',
        label: (
          <Box display="flex" alignItems="center">
            <FaExchangeAlt style={{ marginRight: '8px' }} />
            <FormattedMessage id="type" />
          </Box>
        ),
        render: (expense) => (
          <Tooltip title={expense.shared ? `Shared with ${expense.sharedWith}` : expense.isDebt ? `Debt from ${expense.debtFrom}` : ''}>
            <Typography variant="body2">
              {expense.isDebt ? (
                <FormattedMessage id="debt" />
              ) : expense.shared ? (
                <FormattedMessage id="shared" />
              ) : (
                '-'
              )}
            </Typography>
          </Tooltip>
        ),
      },
      {
        id: 'actions',
        label: <FormattedMessage id="actions" />,
        render: (expense) => (
          <>
            {editingExpense?.id === expense.id ? (
              <>
                <IconButton onClick={handleSaveEdit} size="small">
                  <FaSave />
                </IconButton>
                <IconButton onClick={handleCancelEdit} size="small">
                  <FaTimes />
                </IconButton>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id={`upload-file-${expense.id}`}
                  type="file"
                  onChange={handleFileChange}
                />
                <label htmlFor={`upload-file-${expense.id}`}>
                  <IconButton component="span" size="small">
                    <FaUpload />
                  </IconButton>
                </label>
                {editingFile && (
                  <Typography variant="caption" display="block">
                    {editingFile.name}
                  </Typography>
                )}
              </>
            ) : (
              <>
                <IconButton onClick={() => handleEdit(expense)} size="small">
                  <FaEdit />
                </IconButton>
                <IconButton onClick={() => handleDelete(expense.id)} size="small">
                  <FaTrash />
                </IconButton>
                {expense.fileUrl && (
                  <IconButton onClick={() => handleOpenReceipt(expense.fileUrl || '')} size="small">
                    <FaExchangeAlt />
                  </IconButton>
                )}
              </>
            )}
          </>
        ),
      },
    ]);
  }, [intl, payers, categories, payerColors, editingExpense, editingFile]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(columns);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setColumns(items);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box className="filtered-expenses">
        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={2}>
              <FormControl fullWidth size="small">
                <InputLabel id="payer-select-label"><FormattedMessage id="payer" /></InputLabel>
                <Select
                  labelId="payer-select-label"
                  value={selectedPayer}
                  label={intl.formatMessage({ id: "payer" })}
                  onChange={(e) => setSelectedPayer(e.target.value as string)}
                >
                  <MenuItem value=""><FormattedMessage id="allPayers" /></MenuItem>
                  {payers.map(payer => (
                    <MenuItem key={payer} value={payer}>{payer}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <FormControl fullWidth size="small">
                <InputLabel id="category-select-label"><FormattedMessage id="category" /></InputLabel>
                <Select
                  labelId="category-select-label"
                  value={selectedCategory}
                  label={intl.formatMessage({ id: "category" })}
                  onChange={(e) => setSelectedCategory(e.target.value as string)}
                >
                  <MenuItem value=""><FormattedMessage id="allCategories" /></MenuItem>
                  {sortedCategories.map(category => (
                    <MenuItem key={category} value={category}>{category}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                fullWidth
                size="small"
                label={intl.formatMessage({ id: "searchDescription" })}
                value={descriptionFilter}
                onChange={(e) => setDescriptionFilter(e.target.value)}
                InputProps={{
                  startAdornment: <FaSearch style={{ marginRight: '8px' }} />,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DatePicker
                label={intl.formatMessage({ id: "startDate" })}
                value={startDate}
                onChange={(date) => setStartDate(date)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DatePicker
                label={intl.formatMessage({ id: "endDate" })}
                value={endDate}
                onChange={(date) => setEndDate(date)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<FaFileExport />}
                onClick={exportToCSV}
                fullWidth
                sx={{ height: '40px' }} // Adjust the height to match other inputs
              >
                <FormattedMessage id="exportCSV" />
              </Button>
            </Grid>
          </Grid>
        </Paper>

        <TableContainer sx={{ flexGrow: 1, overflow: 'auto' }}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} sx={{ fontWeight: 'bold' }}>
                    <Box display="flex" alignItems="center">
                      {column.label}
                      {column.id !== 'actions' && (
                        <IconButton size="small" onClick={() => requestSort(column.id as SortableExpenseKey)}>
                          {sortConfig?.key === column.id ? (
                            sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />
                          ) : (
                            <FaSort />
                          )}
                        </IconButton>
                      )}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedExpenses.map(expense => (
                <TableRow key={expense.id}>
                  {columns.map(column => (
                    <TableCell key={column.id}>
                      {column.render(
                        expense,
                        editingExpense?.id === expense.id,
                        editingExpense,
                        handleEditChange,
                        handleSharedChange
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box mt={2}>
          <Typography variant="h6">
            <FormattedMessage id="total" />: €{totalExpenses.toFixed(2)}
          </Typography>
        </Box>

        <Dialog open={openReceiptDialog} onClose={handleCloseReceipt} maxWidth="md" fullWidth>
          <DialogTitle><FormattedMessage id="receipt" /></DialogTitle>
          <DialogContent>
            {selectedReceipt && (
              <img src={selectedReceipt} alt="Receipt" style={{ width: '100%', height: 'auto' }} />
            )}
          </DialogContent>
        </Dialog>
      </Box>
    </LocalizationProvider>
  );
};

export default FilteredExpenses;