import React, { useState, useMemo, useEffect } from 'react';
import { Expense } from '../types/Expense';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Typography, Grid, Select, MenuItem, FormControl, InputLabel, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Paper, Tooltip, Card, CardContent, Chip, Checkbox, FormControlLabel, Divider, CircularProgress } from '@mui/material';
import { FaFilePdf, FaFileCsv, FaCalendarAlt, FaTag, FaAlignLeft, FaEuroSign, FaUser, FaExchangeAlt, FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import CustomChip from './CustomChip';

interface ExpenseReportProps {
  expenses: Expense[];
  payers: string[];
  payerColors: { [key: string]: string };
  onUpdateExpense: (updatedExpense: Expense) => Promise<void>;
}

type SortableExpenseKey = keyof Omit<Expense, 'id' | 'fileUrl'> | 'type' | 'sharedWithOrDebtFrom';

type SortConfig = {
  key: SortableExpenseKey;
  direction: 'asc' | 'desc';
} | null;

const ExpenseReport: React.FC<ExpenseReportProps> = ({ expenses, payers, payerColors, onUpdateExpense }) => {
  const intl = useIntl();
  const [selectedMonth, setSelectedMonth] = useState<number>(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const [selectedPayers, setSelectedPayers] = useState<string[]>(payers);
  const [sortConfig, setSortConfig] = useState<SortConfig>({ key: 'date', direction: 'asc' });
  const [isLoading, setIsLoading] = useState(true);

  const months = Array.from({ length: 12 }, (_, i) => i);
  const years = Array.from(new Set(expenses.map(e => new Date(e.date).getFullYear()))).sort((a, b) => b - a);

  const filteredExpenses = useMemo(() => {
    return expenses.filter(expense => {
      const expenseDate = new Date(expense.date);
      return expenseDate.getMonth() === selectedMonth && 
             expenseDate.getFullYear() === selectedYear &&
             (selectedPayers.includes(expense.payer) || 
              (expense.shared && selectedPayers.includes(expense.sharedWith || '')) ||
              (expense.isDebt && selectedPayers.includes(expense.debtFrom || '')));
    });
  }, [expenses, selectedMonth, selectedYear, selectedPayers]);

  const sortedExpenses = useMemo(() => {
    let sortableExpenses = filteredExpenses.filter(expense => 
      expense.shared && 
      expense.sharedWith && 
      selectedPayers.includes(expense.payer) && 
      selectedPayers.includes(expense.sharedWith)
    );
    
    if (sortConfig !== null) {
      sortableExpenses.sort((a, b) => {
        let aValue: any = a[sortConfig.key as keyof Expense];
        let bValue: any = b[sortConfig.key as keyof Expense];

        if (sortConfig.key === 'type') {
          aValue = a.isDebt ? 'debt' : a.shared ? 'shared' : '-';
          bValue = b.isDebt ? 'debt' : b.shared ? 'shared' : '-';
        } else if (sortConfig.key === 'sharedWithOrDebtFrom') {
          aValue = a.shared ? a.sharedWith : a.isDebt ? a.debtFrom : '-';
          bValue = b.shared ? b.sharedWith : b.isDebt ? b.debtFrom : '-';
        }

        if (typeof aValue === 'string') aValue = aValue.toLowerCase();
        if (typeof bValue === 'string') bValue = bValue.toLowerCase();

        if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
      });
    }
    return sortableExpenses;
  }, [filteredExpenses, sortConfig, selectedPayers]);

  const requestSort = (key: SortableExpenseKey) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const calculateDebts = () => {
    const debts: Record<string, Record<string, number>> = {};
    selectedPayers.forEach(payer => {
      debts[payer] = {};
      selectedPayers.forEach(otherPayer => {
        if (payer !== otherPayer) {
          debts[payer][otherPayer] = 0;
        }
      });
    });

    filteredExpenses.forEach(expense => {
      if (expense.shared && expense.sharedWith && selectedPayers.includes(expense.payer) && selectedPayers.includes(expense.sharedWith)) {
        const halfAmount = expense.amount / 2;
        debts[expense.sharedWith][expense.payer] += halfAmount;
        debts[expense.payer][expense.sharedWith] -= halfAmount;
      }
      if (expense.isDebt && expense.debtFrom && selectedPayers.includes(expense.payer) && selectedPayers.includes(expense.debtFrom)) {
        debts[expense.payer][expense.debtFrom] += expense.amount;
      }
    });

    return debts;
  };

  const debtSummary = useMemo(() => {
    const debts = calculateDebts();
    return Object.entries(debts)
      .flatMap(([payer, owed]) =>
        Object.entries(owed)
          .filter(([_, amount]) => amount > 0)
          .map(([owedTo, amount]) => ({
            from: payer,
            to: owedTo,
            amount: amount
          }))
      )
      .sort((a, b) => b.amount - a.amount);
  }, [filteredExpenses, payers]);

  const totalDebt = useMemo(() => {
    return debtSummary.reduce((sum, debt) => sum + debt.amount, 0);
  }, [debtSummary]);

  const generatePDF = () => {
    const doc = new jsPDF();
    doc.text('Expense Report', 14, 15);
    doc.setFontSize(12);
    doc.text(`Month: ${intl.formatMessage({ id: `month.${selectedMonth}` })}`, 14, 25);
    doc.text(`Year: ${selectedYear}`, 14, 32);
    
    // Add debt summary to PDF
    doc.setFontSize(14);
    doc.setFont('', 'bold');
    doc.text('Debt Summary:', 14, 42);
    doc.setFont('', 'normal');
    doc.setFontSize(12);
    
    let yPos = 48;
    debtSummary.forEach((debt, index) => {
      if (index === 0) {
        doc.setTextColor(255, 0, 0);
      }
      doc.text(`${debt.from} owes ${debt.to}: €${debt.amount.toFixed(2)}`, 14, yPos);
      if (index === 0) {
        doc.setTextColor(0, 0, 0);
      }
      yPos += 6;
    });
    
    const tableColumn = ["Category", "Description", "Date", "Amount", "Payer", "Type"];
    const tableRows = sortedExpenses.map(expense => [
      expense.category,
      expense.description,
      new Date(expense.date).toLocaleDateString(),
      `€${expense.amount.toFixed(2)}`,
      expense.payer,
      expense.isDebt ? 'Debt' : expense.shared ? 'Shared' : '-'
    ]);

    (doc as any).autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: yPos + 10
    });

    doc.save('expense_report.pdf');
  };

  const generateCSV = () => {
    const csvContent = "data:text/csv;charset=utf-8," 
      + "\uFEFF" // BOM para suportar caracteres especiais
      + "Category,Description,Date,Amount,Payer,Type\n"
      + sortedExpenses.map(expense => {
          return `"${expense.category}","${expense.description}","${new Date(expense.date).toLocaleDateString()}","€${expense.amount.toFixed(2)}","${expense.payer}","${expense.isDebt ? 'Debt' : expense.shared ? 'Shared' : '-'}"`;
        }).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "expense_report.csv");
    document.body.appendChild(link);
    link.click();
  };

  const handlePayerSelection = (payer: string) => {
    setSelectedPayers(prev => 
      prev.includes(payer) 
        ? prev.filter(p => p !== payer) 
        : [...prev, payer]
    );
  };

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      // Adicione aqui qualquer lógica de carregamento necessária
      setIsLoading(false);
    };

    loadData();
  }, []);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      gap: 2, 
      width: '100%', 
      height: '100%',
      overflow: 'auto',
      padding: '16px', // Adicione padding aqui se necessário
    }}>
      <Paper elevation={3} sx={{ p: 2, width: '100%' }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel id="month-select-label">
                <FormattedMessage id="month" />
              </InputLabel>
              <Select
                labelId="month-select-label"
                value={selectedMonth}
                label={intl.formatMessage({ id: 'month' })}
                onChange={(e) => setSelectedMonth(Number(e.target.value))}
              >
                {months.map((month) => (
                  <MenuItem key={month} value={month}>
                    {intl.formatMessage({ id: `month.${month}` })}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel id="year-select-label">
                <FormattedMessage id="year" />
              </InputLabel>
              <Select
                labelId="year-select-label"
                value={selectedYear}
                label={intl.formatMessage({ id: 'year' })}
                onChange={(e) => setSelectedYear(Number(e.target.value))}
              >
                {years.map((year) => (
                  <MenuItem key={year} value={year}>{year}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {payers.map(payer => (
                <FormControlLabel
                  key={payer}
                  control={
                    <Checkbox
                      checked={selectedPayers.includes(payer)}
                      onChange={() => handlePayerSelection(payer)}
                      color="primary"
                    />
                  }
                  label={payer}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mb: 2 }}>
        <Button
          variant="contained"
          startIcon={<FaFilePdf />}
          onClick={generatePDF}
          sx={{
            backgroundColor: 'red',
            color: 'white',
            '&:hover': {
              backgroundColor: 'darkred',
            },
            fontSize: '0.7rem',
            padding: '3px 6px',
            minWidth: '60px',
            height: '24px',
          }}
        >
          <FormattedMessage id="exportAsPDF" />
        </Button>
        <Button
          variant="contained"
          startIcon={<FaFileCsv />}
          onClick={generateCSV}
          color="success"
          sx={{
            fontSize: '0.7rem',
            padding: '3px 6px',
            minWidth: '60px',
            height: '24px',
          }}
        >
          <FormattedMessage id="exportAsCSV" />
        </Button>
      </Box>

      <Card sx={{ width: '100%', flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h6" gutterBottom>
            <FormattedMessage id="debtSummary" />
          </Typography>
          <TableContainer sx={{ flexGrow: 1 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}><FormattedMessage id="debtor" /></TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}><FormattedMessage id="creditor" /></TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}><FormattedMessage id="amount" /></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {debtSummary.map((debt, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <CustomChip
                        label={debt.from}
                        color={payerColors[debt.from] || '#E0E0E0'}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <CustomChip
                        label={debt.to}
                        color={payerColors[debt.to] || '#E0E0E0'}
                        size="small"
                      />
                    </TableCell>
                    <TableCell align="right">€{debt.amount.toFixed(2)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6" align="right" color="error">
            <FormattedMessage id="totalDebt" />: €{totalDebt.toFixed(2)}
          </Typography>
        </CardContent>
      </Card>

      <Paper elevation={3} sx={{ flexGrow: 1, overflow: 'auto', width: '100%' }}>
        <TableContainer>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                {[
                  { id: 'category', icon: FaTag, label: 'category' },
                  { id: 'description', icon: FaAlignLeft, label: 'description' },
                  { id: 'date', icon: FaCalendarAlt, label: 'date' },
                  { id: 'amount', icon: FaEuroSign, label: 'amount' },
                  { id: 'payer', icon: FaUser, label: 'payer' },
                  { id: 'type', icon: FaExchangeAlt, label: 'shared' },
                ].map(column => (
                  <TableCell key={column.id} sx={{ fontWeight: 'bold' }}>
                    <Box display="flex" alignItems="center">
                      <column.icon style={{ marginRight: '8px' }} />
                      <FormattedMessage id={column.label} />
                      <IconButton size="small" onClick={() => requestSort(column.id as SortableExpenseKey)}>
                        {sortConfig?.key === column.id ? (
                          sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />
                        ) : (
                          <FaSort />
                        )}
                      </IconButton>
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedExpenses.map(expense => (
                <TableRow key={expense.id}>
                  <TableCell>{expense.category}</TableCell>
                  <TableCell>{expense.description}</TableCell>
                  <TableCell>{new Date(expense.date).toLocaleDateString()}</TableCell>
                  <TableCell align="right">€{expense.amount.toFixed(2)}</TableCell>
                  <TableCell>
                    <CustomChip
                      label={expense.payer}
                      color={payerColors[expense.payer] || '#E0E0E0'}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <Tooltip title={`Shared with ${expense.sharedWith}`}>
                      <span>
                        <FormattedMessage id="shared" />
                      </span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default ExpenseReport;