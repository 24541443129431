import { collection, addDoc, getDocs, updateDoc, deleteDoc, doc, Timestamp, writeBatch, query, where, getDoc, setDoc, serverTimestamp } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage, auth } from "./config";
import { Expense } from "../types/Expense";
import { User } from "firebase/auth";
import { generateRandomColor } from '../utils/colorUtils';
import { Income } from "../types/Income";
import { Investment } from "../types/Investment";
import { FirebaseError } from "firebase/app";

const COLLECTION_NAME = "expenses";
const INCOME_COLLECTION = "income";

export const expensesCache = new Map<string, Expense[]>();
export const incomeCache = new Map<string, Income[]>();

export const getPayers = async (): Promise<string[]> => {
  try {
    const expensesCollection = collection(db, "expenses");
    const querySnapshot = await getDocs(expensesCollection);
    const payers = new Set<string>();
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      if (data.payer) payers.add(data.payer);
      if (data.sharedWith) {
        // Check if sharedWith is an array
        if (Array.isArray(data.sharedWith)) {
          data.sharedWith.forEach(payer => payers.add(payer));
        } else {
          payers.add(data.sharedWith);
        }
      }
    });
    return Array.from(payers);
  } catch (error) {
    console.error("Error getting payers:", error);
    return [];
  }
};

export const addExpense = async (expense: Omit<Expense, 'id'>, user: User, file?: File): Promise<Expense> => {
  try {
    let fileUrl = '';
    if (file) {
      const storageRef = ref(storage, `receipts/${user.uid}/${file.name}`);
      const uploadResult = await uploadBytes(storageRef, file);
      fileUrl = await getDownloadURL(storageRef);
    }

    const expenseWithTimestamp = {
      ...expense,
      userId: user.uid,
      payer: expense.payer || '',
      sharedWith: expense.sharedWith || '',
      date: Timestamp.fromDate(new Date(expense.date)),
      fileUrl,
      isDebt: expense.isDebt || false, // Adicionando a propriedade isDebt
      debtFrom: expense.debtFrom || null // Adicionando a propriedade debtFrom
    };
    
    const docRef = await addDoc(collection(db, COLLECTION_NAME), expenseWithTimestamp);
    const newExpense = { 
      ...expense, 
      id: docRef.id, 
      date: expenseWithTimestamp.date.toDate().toISOString().split('T')[0],
      fileUrl 
    };
    updateExpenseCache(user.uid, newExpense);
    console.log("Expense added to Firestore:", newExpense);
    return newExpense;
  } catch (error) {
    console.error("Error in addExpense function:", error);
    throw new Error(`Failed to add expense: ${error instanceof Error ? error.message : 'Unknown error'}`);
  }
};

export const getExpenses = async (user: User): Promise<Expense[]> => {
  try {
    const cacheKey = user.uid;
    if (expensesCache.has(cacheKey)) {
      return expensesCache.get(cacheKey)!;
    }

    console.log("Getting expenses for user:", user.uid);
    const expensesCollection = collection(db, "expenses");
    const q = query(expensesCollection, where("userId", "==", user.uid));
    const querySnapshot = await getDocs(q);
    
    const expenses: Expense[] = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      date: doc.data().date.toDate().toISOString().split('T')[0]
    } as Expense));

    expensesCache.set(cacheKey, expenses);
    return expenses;
  } catch (error) {
    console.error("Error in getExpenses function:", error);
    throw new Error(`Failed to get expenses: ${error instanceof Error ? error.message : 'Unknown error'}`);
  }
};

export const updateExpense = async (expense: Expense, user: User, file?: File): Promise<void> => {
  try {
    const expenseRef = doc(db, "expenses", expense.id);
    const expenseDoc = await getDoc(expenseRef);

    if (!expenseDoc.exists()) {
      throw new Error("Expense does not exist");
    }

    const expenseData = expenseDoc.data();
    if (expenseData.userId !== user.uid) {
      throw new Error("You do not have permission to update this expense");
    }

    let fileUrl = expense.fileUrl;
    if (file) {
      const storageRef = ref(storage, `receipts/${user.uid}/${file.name}`);
      await uploadBytes(storageRef, file);
      fileUrl = await getDownloadURL(storageRef);
    }

    const { id, ...updateData } = expense;
    
    await updateDoc(expenseRef, {
      ...updateData,
      payer: updateData.payer || '',
      sharedWith: updateData.sharedWith || '',
      date: Timestamp.fromDate(new Date(expense.date)),
      shared: expense.shared,
      fileUrl,
    });
    console.log("Expense updated successfully");
    updateExpenseCache(user.uid, { ...expense, fileUrl });
  } catch (error) {
    console.error("Error updating expense: ", error);
    throw new Error("Failed to update expense");
  }
};

export const deleteExpense = async (id: string, user: User): Promise<void> => {
  try {
    const expenseRef = doc(db, "expenses", id);
    const expenseDoc = await getDoc(expenseRef);

    if (expenseDoc.exists() && expenseDoc.data().userId === user.uid) {
      await deleteDoc(expenseRef);
      console.log("Expense deleted from Firestore:", id);
      const expenses = expensesCache.get(user.uid) || [];
      expensesCache.set(user.uid, expenses.filter(e => e.id !== id));
    } else {
      throw new Error("You do not have permission to delete this expense");
    }
  } catch (error) {
    console.error("Error deleting expense: ", error);
    throw new Error("Failed to delete expense");
  }
};

export const updateCategory = async (oldCategory: string, newCategory: string): Promise<void> => {
  const batch = writeBatch(db);
  const expensesRef = collection(db, "expenses");
  const q = query(expensesRef, where("category", "==", oldCategory));
  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((doc) => {
    batch.update(doc.ref, { category: newCategory });
  });

  await batch.commit();
};

export const getPayerColors = async (): Promise<{ [key: string]: string }> => {
  try {
    console.log("Attempting to fetch payer colors...");
    const payerColorsDoc = await getDoc(doc(db, "payerColors", "colors"));
    console.log("Payer colors document exists:", payerColorsDoc.exists());
    if (payerColorsDoc.exists()) {
      const data = payerColorsDoc.data();
      console.log("Payer colors data:", data);
      return data as { [key: string]: string };
    } else {
      console.log("Payer colors document does not exist. Returning empty object.");
      return {};
    }
  } catch (error) {
    console.error("Error fetching payer colors:", error);
    if (error instanceof Error) {
      console.error("Error message:", error.message);
      console.error("Error stack:", error.stack);
    }
    // Instead of throwing, return an empty object
    return {};
  }
};

export const initializePayerColors = async () => {
  try {
    const payerColorsRef = doc(db, "payerColors", "colors");
    const payerColorsDoc = await getDoc(payerColorsRef);
    
    if (!payerColorsDoc.exists()) {
      const initialColors = {
        "Bruno": "#64B5F6",
        "Simone": "#FFB74D",
        "Bárbara": "#81C784"
      };
      await setDoc(payerColorsRef, initialColors);
      console.log("Payer colors initialized");
    } else {
      const existingColors = payerColorsDoc.data();
      const payers = await getPayers(); // Agora esta função está definida
      const updatedColors = { ...existingColors };
      let hasNewColors = false;

      payers.forEach((payer: string) => { // Adicionamos o tipo explicitamente aqui
        if (!updatedColors[payer]) {
          updatedColors[payer] = generateRandomColor();
          hasNewColors = true;
        }
      });

      if (hasNewColors) {
        await setDoc(payerColorsRef, updatedColors);
        console.log("New payer colors added");
      }
    }
  } catch (error) {
    console.error("Error initializing payer colors:", error);
  }
};

export const updatePayerColor = async (payer: string, color: string): Promise<void> => {
  try {
    const payerColorsRef = doc(db, "payerColors", "colors");
    await setDoc(payerColorsRef, { [payer]: color }, { merge: true });
  } catch (error) {
    console.error("Error updating payer color:", error);
    throw new Error("Failed to update payer color");
  }
};

// Explicitly export types
export type { Expense };

// Add this line at the end of the file
export {};

export const deleteAllExpenses = async (user: User): Promise<void> => {
  try {
    const expensesRef = collection(db, "expenses");
    const q = query(expensesRef, where("userId", "==", user.uid));
    const querySnapshot = await getDocs(q);

    const batch = writeBatch(db);
    querySnapshot.forEach((doc) => {
      batch.delete(doc.ref);
    });

    await batch.commit();
    console.log("All expenses deleted successfully");
    expensesCache.delete(user.uid);
  } catch (error) {
    console.error("Error deleting all expenses: ", error);
    throw new Error("Failed to delete all expenses");
  }
};

export const addIncome = async (income: Omit<Income, 'id' | 'userId'>, userId: string, file?: File): Promise<Income> => {
  try {
    const incomeWithTimestamp = {
      ...income,
      date: Timestamp.fromDate(new Date(income.date)),
      userId,
    };

    const docRef = await addDoc(collection(db, INCOME_COLLECTION), incomeWithTimestamp);

    const newIncome = {
      ...incomeWithTimestamp,
      id: docRef.id, 
      date: incomeWithTimestamp.date.toDate().toISOString().split('T')[0],
    };
    updateIncomeCache(userId, newIncome);
    return newIncome;
  } catch (error) {
    console.error("Error in addIncome function:", error);
    throw new Error(`Failed to add income: ${error instanceof Error ? error.message : 'Unknown error'}`);
  }
};

export const getIncome = async (user: User): Promise<Income[]> => {
  try {
    const cacheKey = user.uid;
    if (incomeCache.has(cacheKey)) {
      return incomeCache.get(cacheKey)!;
    }

    console.log("Fetching income for user:", user.uid);
    const incomeRef = collection(db, INCOME_COLLECTION);
    const incomeQuery = query(incomeRef, where("userId", "==", user.uid));
    const querySnapshot = await getDocs(incomeQuery);
    
    const incomeData = querySnapshot.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
      date: doc.data().date instanceof Timestamp ? doc.data().date.toDate().toISOString().split('T')[0] : doc.data().date,
    } as Income));

    incomeCache.set(cacheKey, incomeData);
    return incomeData;
  } catch (error) {
    console.error("Error fetching income:", error);
    throw new Error(`Failed to fetch income: ${error instanceof Error ? error.message : 'Unknown error'}`);
  }
};

export const updateIncome = async (income: Income, user: User): Promise<void> => {
  try {
    const incomeRef = doc(db, INCOME_COLLECTION, income.id);
    const incomeDoc = await getDoc(incomeRef);

    if (!incomeDoc.exists()) {
      throw new Error("Income entry does not exist");
    }

    const incomeData = incomeDoc.data();
    if (incomeData.userId !== user.uid) {
      throw new Error("You do not have permission to update this income entry");
    }

    const { id, ...updateData } = income;
    
    await updateDoc(incomeRef, {
      ...updateData,
      date: Timestamp.fromDate(new Date(income.date)),
    });
    console.log("Income entry updated successfully");
    updateIncomeCache(user.uid, income);
  } catch (error) {
    console.error("Error updating income entry: ", error);
    throw new Error("Failed to update income entry");
  }
};

export const deleteIncome = async (id: string, user: User): Promise<void> => {
  try {
    const incomeRef = doc(db, INCOME_COLLECTION, id);
    const incomeDoc = await getDoc(incomeRef);

    if (incomeDoc.exists() && incomeDoc.data().userId === user.uid) {
      await deleteDoc(incomeRef);
      console.log("Income entry deleted successfully");
      const incomes = incomeCache.get(user.uid) || [];
      incomeCache.set(user.uid, incomes.filter(i => i.id !== id));
    } else {
      throw new Error("You do not have permission to delete this income entry");
    }
  } catch (error) {
    console.error("Error deleting income entry: ", error);
    throw new Error("Failed to delete income entry");
  }
};

export const updateIncomeCategory = async (oldCategory: string, newCategory: string, user: User): Promise<void> => {
  const incomeRef = collection(db, 'income');
  const q = query(incomeRef, where('userId', '==', user.uid), where('category', '==', oldCategory));
  const querySnapshot = await getDocs(q);

  const batch = writeBatch(db);
  querySnapshot.forEach((doc) => {
    batch.update(doc.ref, { category: newCategory });
  });

  await batch.commit();
};

// Add investment
export const addInvestment = async (userId: string, investment: Omit<Investment, 'id'>): Promise<void> => {
  try {
    console.log('Attempting to add investment for user:', userId);
    console.log('Investment data:', JSON.stringify(investment, null, 2));
    console.log('Current user UID:', auth.currentUser?.uid);

    const investmentsRef = collection(db, 'investments');
    const newInvestmentRef = doc(investmentsRef);
    await setDoc(newInvestmentRef, {
      ...investment,
      id: newInvestmentRef.id,
      userId: userId,
    });
    console.log('Investment added with ID: ', newInvestmentRef.id);
  } catch (error) {
    console.error("Error adding investment: ", error);
    if (error instanceof FirebaseError) {
      console.error("Firebase error code:", error.code);
      console.error("Firebase error message:", error.message);
      if (error.code === 'permission-denied') {
        throw new Error("You don't have permission to add investments. Please check your account permissions.");
      }
    }
    throw error;
  }
};

// Get investments for a user
export const getInvestments = async (userId: string) => {
  try {
    console.log('Fetching investments for user:', userId);
    const q = query(collection(db, 'investments'), where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    console.log('Fetched investments count:', querySnapshot.size);
    return querySnapshot.docs.map(doc => {
      const data = doc.data();
      return {
        id: doc.id,
        ...data,
        purchaseDate: data.purchaseDate instanceof Timestamp ? data.purchaseDate.toDate() : data.purchaseDate,
      } as Investment;
    });
  } catch (error) {
    console.error('Error getting investments: ', error);
    throw error;
  }
};

// Update investment
export const updateInvestment = async (id: string, investment: Partial<Investment>) => {
  try {
    const investmentRef = doc(db, 'investments', id);
    await updateDoc(investmentRef, {
      ...investment,
      purchaseDate: investment.purchaseDate ? Timestamp.fromDate(investment.purchaseDate) : null,
    });
  } catch (error) {
    console.error('Error updating investment: ', error);
    throw error;
  }
};

// Delete investment
export const deleteInvestment = async (id: string) => {
  try {
    await deleteDoc(doc(db, 'investments', id));
  } catch (error) {
    console.error('Error deleting investment: ', error);
    throw error;
  }
};

// Add this new function
export const getCurrentTokenPrices = async (tokens: string[]): Promise<{[key: string]: number}> => {
  try {
    // This is a placeholder. In a real application, you would call an API to get current prices.
    // For demonstration purposes, we're generating random prices.
    const prices: {[key: string]: number} = {};
    tokens.forEach(token => {
      prices[token] = Math.random() * 1000; // Random price between 0 and 1000
    });
    return prices;
  } catch (error) {
    console.error('Error getting current token prices: ', error);
    throw error;
  }
};

export const updateExpenseCache = (userId: string, expense: Expense) => {
  const expenses = expensesCache.get(userId) || [];
  const index = expenses.findIndex(e => e.id === expense.id);
  if (index !== -1) {
    expenses[index] = expense;
  } else {
    expenses.push(expense);
  }
  expensesCache.set(userId, expenses);
  console.log("Expense cache updated:", expensesCache.get(userId));
};

export const updateIncomeCache = (userId: string, income: Income) => {
  const incomes = incomeCache.get(userId) || [];
  const index = incomes.findIndex(i => i.id === income.id);
  if (index !== -1) {
    incomes[index] = income;
  } else {
    incomes.push(income);
  }
  incomeCache.set(userId, incomes);
};

export const batchUpdateExpenses = async (user: User, expenses: Expense[]) => {
  const batch = writeBatch(db);
  expenses.forEach(expense => {
    const expenseRef = doc(db, "expenses", expense.id);
    const { id, ...expenseData } = expense;
    batch.update(expenseRef, expenseData);
    updateExpenseCache(user.uid, expense);
  });
  await batch.commit();
};

export const batchUpdateIncomes = async (user: User, incomes: Income[]) => {
  const batch = writeBatch(db);
  incomes.forEach(income => {
    const incomeRef = doc(db, INCOME_COLLECTION, income.id);
    const { id, ...incomeData } = income;
    batch.update(incomeRef, incomeData);
    updateIncomeCache(user.uid, income);
  });
  await batch.commit();
};