import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { IntlProvider } from 'react-intl';
import ptMessages from './locales/pt-PT.json';
import enMessages from './locales/en-US.json';

const messages: { [key: string]: any } = {
  'pt-PT': ptMessages,
  'en-US': enMessages,
};

const Root: React.FC = () => {
  const [locale, setLocale] = React.useState<'pt-PT' | 'en-US'>('en-US');

  return (
    <React.StrictMode>
      <IntlProvider messages={messages[locale]} locale={locale} defaultLocale="en-US">
        <App locale={locale} setLocale={setLocale} />
      </IntlProvider>
    </React.StrictMode>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));