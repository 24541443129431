import React, { useState, useEffect } from 'react';
import { TextField, Select, MenuItem, Button, Box, Typography, InputLabel, FormControl, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemText, IconButton, Tooltip, Snackbar, CircularProgress } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Add, Save, Cancel, Edit, Delete, Settings, Attachment } from '@mui/icons-material';
import { motion } from 'framer-motion';
import { Income } from '../types/Income';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useIntl, FormattedMessage } from 'react-intl';
import Autocomplete from '@mui/material/Autocomplete';

interface IncomeFormProps {
  onAddIncome: (income: Omit<Income, 'id' | 'userId'>, file?: File) => Promise<void>;
  categories: string[];
  onAddCategory: (category: string) => void;
  onEditCategory: (oldCategory: string, newCategory: string) => void;
  initialIncome?: Income;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const IncomeForm: React.FC<IncomeFormProps> = ({ onAddIncome, categories, onAddCategory, onEditCategory, initialIncome }) => {
  const intl = useIntl();
  const [income, setIncome] = useState<Income>(() => {
    if (initialIncome) {
      return initialIncome;
    }
    return {
      id: '',
      userId: '',
      description: '',
      date: '',
      amount: 0,
      category: '',
      source: '',
      payer: 'Bruno',
      token: '',
    };
  });
  const [amountInput, setAmountInput] = useState(initialIncome ? initialIncome.amount.toString() : '');
  const [newCategory, setNewCategory] = useState('');
  const [isAddingCategory, setIsAddingCategory] = useState(false);
  const [sortedCategories, setSortedCategories] = useState<string[]>([]);
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const [editingCategoryName, setEditingCategoryName] = useState('');
  const [newCategoryName, setNewCategoryName] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [descriptionSuggestions, setDescriptionSuggestions] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      setSortedCategories([...categories].sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' })));
      const savedSuggestions = localStorage.getItem('incomeDescriptionSuggestions');
      if (savedSuggestions) {
        setDescriptionSuggestions(JSON.parse(savedSuggestions));
      }
      setIsLoading(false);
    };

    loadData();
  }, [categories]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | { target: { name: string; value: string } }) => {
    const { name, value } = e.target;
    if (name === 'category' && value === 'new') {
      setIsAddingCategory(true);
    } else {
      setIncome(prev => ({
        ...prev,
        [name]: name === 'recurring' ? (e.target as HTMLInputElement).checked : value,
      }));
    }
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const sanitizedValue = value.replace(',', '.');
    if (/^\d*\.?\d{0,2}$/.test(sanitizedValue)) {
      setAmountInput(sanitizedValue);
      setIncome(prev => ({ ...prev, amount: parseFloat(sanitizedValue) || 0 }));
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!income.description || !income.date || !income.category || income.amount <= 0) {
      alert(intl.formatMessage({ id: 'fillAllFields' }));
      return;
    }

    try {
      await onAddIncome(income, selectedFile || undefined);
      setIncome({
        id: '',
        userId: '',
        description: '',
        date: '',
        amount: 0,
        category: '',
        source: '',
        payer: 'Bruno',
        token: '',
      });
      setAmountInput('');
      setSelectedFile(null);
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error adding income:', error);
      setError(intl.formatMessage({ id: 'errorAddingIncome' }));
    }
  };

  const handleAddCategory = () => {
    const trimmedCategory = newCategory.trim();
    if (trimmedCategory !== '') {
      if (!categories.includes(trimmedCategory)) {
        onAddCategory(trimmedCategory);
        setIsAddingCategory(false);
        setIncome({...income, category: trimmedCategory});
        setNewCategory('');
      } else {
        alert(`${intl.formatMessage({ id: 'categoryExists' })} "${trimmedCategory}".`);
      }
    }
  };

  const handleOpenCategoryDialog = () => {
    setOpenCategoryDialog(true);
  };

  const handleCloseCategoryDialog = () => {
    setOpenCategoryDialog(false);
    setEditingCategoryName('');
    setNewCategoryName('');
  };

  const handleEditCategoryStart = (category: string) => {
    setEditingCategoryName(category);
    setNewCategoryName(category);
  };

  const handleEditCategoryConfirm = () => {
    if (newCategoryName.trim() !== '' && newCategoryName !== editingCategoryName) {
      onEditCategory(editingCategoryName, newCategoryName.trim());
      setEditingCategoryName('');
      setNewCategoryName('');
      // Atualizar a categoria do income se for a categoria que está sendo editada
      if (income.category === editingCategoryName) {
        setIncome(prev => ({ ...prev, category: newCategoryName.trim() }));
      }
    }
  };

  const handleDeleteCategoryConfirm = (category: string) => {
    if (window.confirm(`${intl.formatMessage({ id: 'confirmDelete' })} "${category}"?`)) {
      // Implement category deletion logic here
    }
  };

  const handleDateChange = (newValue: Date | null) => {
    if (newValue instanceof Date) {
      // Ensure the date is not affected by timezone
      const localDate = new Date(newValue.getTime() - newValue.getTimezoneOffset() * 60000);
      setIncome(prev => ({ ...prev, date: localDate.toISOString().split('T')[0] }));
    } else if (newValue === null) {
      setIncome(prev => ({ ...prev, date: '' }));
    }
  };

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleDescriptionChange = (event: React.ChangeEvent<{}>, value: string | null) => {
    if (value) {
      setIncome(prev => ({ ...prev, description: value }));
      
      // Update suggestions
      if (!descriptionSuggestions.includes(value)) {
        const newSuggestions = [...descriptionSuggestions, value].slice(-20); // Keep last 20 suggestions
        setDescriptionSuggestions(newSuggestions);
        localStorage.setItem('incomeDescriptionSuggestions', JSON.stringify(newSuggestions));
      }
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        style={{ width: '100%', height: '100%' }}
      >
        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2, height: '100%' }}>
          {error && <Typography color="error">{error}</Typography>}
          
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FormControl fullWidth>
              <InputLabel id="category-label"><FormattedMessage id="category" /></InputLabel>
              <Select
                labelId="category-label"
                label={intl.formatMessage({ id: 'category' })}
                name="category"
                value={income.category}
                onChange={handleChange}
                required
              >
                <MenuItem value=""><FormattedMessage id="selectCategory" /></MenuItem>
                {sortedCategories.map((category) => (
                  <MenuItem key={category} value={category}>{category}</MenuItem>
                ))}
                <MenuItem value="new">+ <FormattedMessage id="addNewCategory" /></MenuItem>
              </Select>
            </FormControl>
            <Tooltip title={intl.formatMessage({ id: 'manageCategories' })}>
              <IconButton
                size="small"
                onClick={handleOpenCategoryDialog}
                sx={{ ml: 1 }}
              >
                <Settings fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>

          {isAddingCategory && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <TextField
                label={intl.formatMessage({ id: 'newCategory' })}
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
                fullWidth
              />
              <Button variant="contained" color="primary" startIcon={<Add />} onClick={handleAddCategory}>
                <FormattedMessage id="add" />
              </Button>
            </Box>
          )}
          
          <Autocomplete
            freeSolo
            options={descriptionSuggestions}
            value={income.description}
            onChange={handleDescriptionChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label={intl.formatMessage({ id: 'description' })}
                name="description"
                required
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable browser autocomplete
                }}
              />
            )}
          />
          
          <DatePicker
            label={intl.formatMessage({ id: 'date' })}
            value={income.date ? new Date(income.date) : null}
            onChange={(newValue) => handleDateChange(newValue as Date | null)}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                required
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {params.InputProps?.endAdornment}
                    </React.Fragment>
                  ),
                }}
                inputProps={{
                  ...params.inputProps,
                  readOnly: true,
                }}
                sx={{
                  '& .MuiInputBase-root': {
                    cursor: 'pointer',
                  },
                  '& .MuiInputBase-input': {
                    cursor: 'pointer',
                  },
                }}
                onClick={(event) => {
                  if (params.inputProps?.onClick) {
                    (params.inputProps.onClick as React.MouseEventHandler<HTMLDivElement>)(event);
                  }
                }}
              />
            )}
          />
          
          <TextField
            label={intl.formatMessage({ id: 'source' })}
            name="source"
            value={income.source}
            onChange={handleChange}
            required
            fullWidth
          />
          
          <TextField
            type="text"
            label={`${intl.formatMessage({ id: 'amount' })} (€)`}
            name="amount"
            value={amountInput}
            onChange={handleAmountChange}
            required
            fullWidth
            InputProps={{ 
              style: { textAlign: 'right' },
            }}
            inputProps={{
              inputMode: 'decimal',
              pattern: '[0-9]*[.,]?[0-9]*'
            }}
            onKeyPress={(e) => {
              const pattern = /[0-9.,]/;
              if (!pattern.test(e.key)) {
                e.preventDefault();
              }
            }}
          />
          
          <Box>
            <input
              accept="image/*,application/pdf"
              style={{ display: 'none' }}
              id="raised-button-file"
              type="file"
              onChange={handleFileChange}
            />
            <label htmlFor="raised-button-file">
              <Button variant="outlined" component="span" startIcon={<Attachment />}>
                <FormattedMessage id="attachReceipt" />
              </Button>
            </label>
            {selectedFile && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                {selectedFile.name}
              </Typography>
            )}
          </Box>

          <Button type="submit" variant="contained" color="primary" startIcon={<Save />}>
            <FormattedMessage id="addIncome" />
          </Button>
        </Box>

        <Dialog open={openCategoryDialog} onClose={handleCloseCategoryDialog}>
          <DialogTitle><FormattedMessage id="manageCategories" /></DialogTitle>
          <DialogContent>
            <List>
              {sortedCategories.map((category) => (
                <ListItem key={category}>
                  {editingCategoryName === category ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}>
                      <TextField
                        value={newCategoryName}
                        onChange={(e) => setNewCategoryName(e.target.value)}
                        fullWidth
                      />
                      <IconButton onClick={handleEditCategoryConfirm}>
                        <Save />
                      </IconButton>
                      <IconButton onClick={() => setEditingCategoryName('')}>
                        <Cancel />
                      </IconButton>
                    </Box>
                  ) : (
                    <>
                      <ListItemText primary={category} />
                      <IconButton onClick={() => handleEditCategoryStart(category)}>
                        <Edit />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteCategoryConfirm(category)}>
                        <Delete />
                      </IconButton>
                    </>
                  )}
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCategoryDialog}><FormattedMessage id="close" /></Button>
          </DialogActions>
        </Dialog>

        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
            <FormattedMessage id="incomeSubmittedSuccessfully" />
          </Alert>
        </Snackbar>
      </motion.div>
    </LocalizationProvider>
  );
};

export default IncomeForm;