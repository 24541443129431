import React, { useState } from 'react';
import { signInWithPopup, GoogleAuthProvider, getAuth } from 'firebase/auth';
import { auth } from '../firebase/config';
import { Button, Typography, Box, CircularProgress, Paper } from '@mui/material';
import { Google as GoogleIcon } from '@mui/icons-material';

interface LoginProps {
  setUser: (user: any | null) => void;
}

const Login: React.FC<LoginProps> = ({ setUser }) => {
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleGoogleLogin = async () => {
    setIsLoading(true);
    try {
      const provider = new GoogleAuthProvider();
      // Adicione estas duas linhas para forçar a seleção de conta
      provider.setCustomParameters({
        prompt: 'select_account'
      });
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
    } catch (error) {
      console.error('Error during Google login:', error);
      setError(error instanceof Error ? error.message : 'An unknown error occurred.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh" bgcolor="#f0f2f5">
      <Paper elevation={3} sx={{ padding: 4, width: '100%', maxWidth: 400 }}>
        <Typography variant="h4" align="center" gutterBottom fontWeight="bold" color="primary">
          Expense Tracker
        </Typography>
        <Typography variant="body1" align="center" sx={{ mb: 4 }}>
          Welcome! Please login to your account
        </Typography>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          startIcon={<GoogleIcon />}
          onClick={handleGoogleLogin}
          disabled={isLoading}
          sx={{ py: 1.5, textTransform: 'none', fontSize: '1rem' }}
        >
          {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Sign in with Google'}
        </Button>
        {error && (
          <Typography color="error" align="center" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
      </Paper>
    </Box>
  );
};

export default Login;