import React, { useState, useMemo } from 'react';
import { Box, Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, FormControl, InputLabel, Select, MenuItem, Grid, Accordion, AccordionSummary, AccordionDetails, IconButton, Tooltip, SelectChangeEvent, TextField, InputAdornment, ChipProps } from '@mui/material';
import { Expense } from '../types/Expense';
import { FormattedMessage, useIntl } from 'react-intl';
import { FaEuroSign, FaChevronDown, FaExpandAlt, FaCompressAlt, FaThList, FaTable, FaTag, FaUser, FaCalendarAlt, FaAlignLeft, FaExchangeAlt, FaSearch, FaSortUp, FaSortDown, FaSort } from 'react-icons/fa';
import CustomChip from './CustomChip';

interface MonthlyExpensesProps {
  expenses: Expense[];
  payers: string[];
  categories: string[];
  payerColors: { [key: string]: string };
}

interface SortConfig {
  key: keyof Expense;
  direction: 'asc' | 'desc';
}

const getExpenseType = (expense: Expense): string => {
  if (expense.isDebt) return 'debt';
  if (expense.shared) return 'shared';
  return 'normal';
};

const getExpenseTypeDisplay = (expense: Expense): { text: string; tooltip?: string } => {
  if (expense.shared) {
    return { 
      text: 'Shared', 
      tooltip: `Shared with ${expense.sharedWith}` 
    };
  }
  if (expense.isDebt) {
    return { text: 'Debt' };
  }
  return { text: '-' };
};

const MonthlyExpenses: React.FC<MonthlyExpensesProps> = ({ expenses, payers, categories, payerColors }) => {
  const intl = useIntl();
  
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState<number>(new Date().getMonth());
  const [selectedPayer, setSelectedPayer] = useState<string>('');
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [viewType, setViewType] = useState<'vertical' | 'horizontal'>('vertical');
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [sortConfig, setSortConfig] = useState<SortConfig>({ key: 'date', direction: 'asc' });
  const [showAllMonths, setShowAllMonths] = useState(false);

  const years = useMemo(() => {
    const uniqueYears = Array.from(new Set(expenses.map(e => new Date(e.date).getFullYear())));
    return uniqueYears.sort((a, b) => b - a);
  }, [expenses]);

  const months = Array.from({ length: 12 }, (_, i) => i);

  const sortedPayers = useMemo(() => [...payers].sort((a, b) => a.localeCompare(b)), [payers]);
  const sortedCategories = useMemo(() => [...categories].sort((a, b) => a.localeCompare(b)), [categories]);

  const filteredExpenses = useMemo(() => {
    return expenses.filter(expense => {
      const expenseDate = new Date(expense.date);
      const yearMatch = expenseDate.getFullYear() === selectedYear;
      const monthMatch = showAllMonths || expenseDate.getMonth() === selectedMonth;
      const payerMatch = selectedPayer 
        ? expense.payer === selectedPayer || 
          (expense.shared && expense.sharedWith === selectedPayer) || 
          (expense.isDebt && expense.debtFrom === selectedPayer)
        : true;
      const categoryMatch = selectedCategory ? expense.category === selectedCategory : true;
      const searchMatch = searchTerm
        ? expense.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
          expense.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
          expense.payer.toLowerCase().includes(searchTerm.toLowerCase())
        : true;
      return yearMatch && monthMatch && payerMatch && categoryMatch && searchMatch;
    });
  }, [expenses, selectedYear, selectedMonth, selectedPayer, selectedCategory, searchTerm, showAllMonths]);

  const expensesByPayer = useMemo(() => {
    const payerTotals: { [key: string]: { total: number, expenses: Expense[] } } = {};
    filteredExpenses.forEach(expense => {
      const relevantPayers = selectedPayer ? [selectedPayer] : payers;
      relevantPayers.forEach(payer => {
        if (!payerTotals[payer]) {
          payerTotals[payer] = { total: 0, expenses: [] };
        }
        if (expense.payer === payer) {
          const amount = expense.shared ? expense.amount / 2 : expense.amount;
          payerTotals[payer].total += amount;
          payerTotals[payer].expenses.push({...expense, amount});
        }
        if (expense.shared && expense.sharedWith === payer) {
          const amount = expense.amount / 2;
          payerTotals[payer].total += amount;
          payerTotals[payer].expenses.push({...expense, amount, payer});
        }
        if (expense.isDebt && expense.debtFrom === payer) {
          payerTotals[payer].total -= expense.amount;
          payerTotals[payer].expenses.push({...expense, amount: -expense.amount, payer});
        }
      });
    });
    return payerTotals;
  }, [filteredExpenses, selectedPayer, payers]);

  const totalExpenses = useMemo(() => {
    if (selectedPayer) {
      return expensesByPayer[selectedPayer]?.total || 0;
    }
    return Object.values(expensesByPayer).reduce((sum, { total }) => sum + total, 0);
  }, [expensesByPayer, selectedPayer]);

  const sortedExpenses = useMemo(() => {
    if (sortConfig.key === null) return filteredExpenses;
    return [...filteredExpenses].sort((a, b) => {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];
      
      // Handle null or undefined values
      if (aValue == null && bValue == null) return 0;
      if (aValue == null) return sortConfig.direction === 'asc' ? -1 : 1;
      if (bValue == null) return sortConfig.direction === 'asc' ? 1 : -1;

      // Compare values
      if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });
  }, [filteredExpenses, sortConfig]);

  const requestSort = (key: keyof Expense) => {
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  const getSortIcon = (columnName: keyof Expense) => {
    if (sortConfig.key === columnName) {
      return sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />;
    }
    return <FaSort />;
  };

  const handleExpandAll = () => {
    setExpandedItems(Object.keys(expensesByPayer));
  };

  const handleCollapseAll = () => {
    setExpandedItems([]);
  };

  const toggleExpanded = (item: string) => {
    setExpandedItems(prev => 
      prev.includes(item) ? prev.filter(i => i !== item) : [...prev, item]
    );
  };

  const handlePayerChange = (event: SelectChangeEvent<string>) => {
    const newPayer = event.target.value;
    setSelectedPayer(newPayer);
    if (newPayer === '') {
      setExpandedItems([]);
    } else {
      setExpandedItems([newPayer]);
    }
  };

  const renderAmount = (expense: Expense, payer: string) => {
    let amount = expense.amount;
    if (expense.shared) {
      amount = expense.amount / 2;
    } else if (expense.isDebt && expense.debtFrom === payer) {
      amount = -expense.amount;
    }
    return (
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <FaEuroSign style={{ marginRight: '4px', fontSize: '0.8rem' }} />
        {amount.toFixed(2)}
      </Box>
    );
  };

  const renderPayerChip = (payer: string) => (
    <CustomChip 
      label={payer} 
      color={payerColors[payer]} 
      size="small"
    />
  );

  const renderVerticalView = () => (
    <Grid item xs={12}>
      <Paper elevation={3} sx={{ p: { xs: 1, sm: 2 } }}>
        {Object.entries(expensesByPayer).map(([payer, { total, expenses }]) => (
          <Accordion 
            key={payer} 
            expanded={expandedItems.includes(payer)}
            onChange={() => toggleExpanded(payer)}
            sx={{ display: selectedPayer && selectedPayer !== payer ? 'none' : 'block' }}
          >
            <AccordionSummary expandIcon={<FaChevronDown />}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <Typography sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '0.9rem', sm: '1rem' } }}>
                  <FaUser style={{ marginRight: '8px' }} />
                  {renderPayerChip(payer)}
                </Typography>
                <Typography sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}>
                  <FaEuroSign style={{ marginRight: '4px', fontSize: '0.8rem' }} />
                  {total.toFixed(2)}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell onClick={() => requestSort('category')}>
                        <Box display="flex" alignItems="center">
                          <FaTag style={{ marginRight: '8px' }} />
                          <Typography fontWeight="bold" fontSize={{ xs: '0.8rem', sm: '0.9rem' }}>
                            <FormattedMessage id="category" />
                          </Typography>
                          {getSortIcon('category')}
                        </Box>
                      </TableCell>
                      <TableCell onClick={() => requestSort('description')}>
                        <Box display="flex" alignItems="center">
                          <FaAlignLeft style={{ marginRight: '8px' }} />
                          <Typography fontWeight="bold" fontSize={{ xs: '0.8rem', sm: '0.9rem' }}>
                            <FormattedMessage id="description" />
                          </Typography>
                          {getSortIcon('description')}
                        </Box>
                      </TableCell>
                      <TableCell align="center" onClick={() => requestSort('date')}>
                        <Box display="flex" alignItems="center" justifyContent="center">
                          <FaCalendarAlt style={{ marginRight: '8px' }} />
                          <Typography fontWeight="bold" fontSize={{ xs: '0.8rem', sm: '0.9rem' }}>
                            <FormattedMessage id="date" />
                          </Typography>
                          {getSortIcon('date')}
                        </Box>
                      </TableCell>
                      <TableCell align="right" onClick={() => requestSort('amount')}>
                        <Box display="flex" alignItems="center" justifyContent="flex-end">
                          <FaEuroSign style={{ marginRight: '8px', fontSize: '0.8rem' }} />
                          <Typography fontWeight="bold" fontSize={{ xs: '0.8rem', sm: '0.9rem' }}>
                            <FormattedMessage id="amount" />
                          </Typography>
                          {getSortIcon('amount')}
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        <Box display="flex" alignItems="center" justifyContent="center">
                          <FaExchangeAlt style={{ marginRight: '8px' }} />
                          <Typography fontWeight="bold" fontSize={{ xs: '0.8rem', sm: '0.9rem' }}>
                            <FormattedMessage id="type" />
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedExpenses.filter(e => e.payer === payer || (e.shared && e.sharedWith === payer) || (e.isDebt && e.debtFrom === payer)).map((expense, index) => (
                      <TableRow key={`${payer}-${expense.id}-${index}`}>
                        <TableCell sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}>
                          {expense.category}
                        </TableCell>
                        <TableCell sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}>
                          {expense.description}
                        </TableCell>
                        <TableCell align="center" sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}>
                          {new Date(expense.date).toLocaleDateString()}
                        </TableCell>
                        <TableCell align="right" sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}>
                          {renderAmount(expense, payer)}
                        </TableCell>
                        <TableCell align="center" sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}>
                          {renderExpenseType(expense)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        ))}
      </Paper>
    </Grid>
  );

  const renderHorizontalView = () => (
    <Grid item xs={12}>
      <Paper elevation={3} sx={{ p: { xs: 1, sm: 2 }, overflowX: 'auto' }}>
        <TableContainer>
          <Table size="small" sx={{ minWidth: { xs: 300, sm: 650 } }}>
            <TableHead>
              <TableRow>
                <TableCell onClick={() => requestSort('payer')}>
                  <Box display="flex" alignItems="center">
                    <FaUser style={{ marginRight: '8px' }} />
                    <FormattedMessage id="payer" />
                    {getSortIcon('payer')}
                  </Box>
                </TableCell>
                <TableCell onClick={() => requestSort('category')}>
                  <Box display="flex" alignItems="center">
                    <FaTag style={{ marginRight: '8px' }} />
                    <FormattedMessage id="category" />
                    {getSortIcon('category')}
                  </Box>
                </TableCell>
                <TableCell onClick={() => requestSort('description')}>
                  <Box display="flex" alignItems="center">
                    <FaAlignLeft style={{ marginRight: '8px' }} />
                    <FormattedMessage id="description" />
                    {getSortIcon('description')}
                  </Box>
                </TableCell>
                <TableCell align="center" onClick={() => requestSort('date')}>
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <FaCalendarAlt style={{ marginRight: '8px' }} />
                    <FormattedMessage id="date" />
                    {getSortIcon('date')}
                  </Box>
                </TableCell>
                <TableCell align="right" onClick={() => requestSort('amount')}>
                  <Box display="flex" alignItems="center" justifyContent="flex-end">
                    <FaEuroSign style={{ marginRight: '8px' }} />
                    <FormattedMessage id="amount" />
                    {getSortIcon('amount')}
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <FaExchangeAlt style={{ marginRight: '8px' }} />
                    <FormattedMessage id="type" />
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(expensesByPayer).map(([payer, { total, expenses }]) => (
                <React.Fragment key={payer}>
                  <TableRow sx={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
                    <TableCell colSpan={4} sx={{ fontWeight: 'bold', fontSize: { xs: '0.8rem', sm: '0.9rem' } }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <FaUser style={{ marginRight: '8px' }} />
                        {renderPayerChip(payer)}
                      </Box>
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold', fontSize: { xs: '0.8rem', sm: '0.9rem' } }}>
                      <Box display="flex" alignItems="center" justifyContent="flex-end">
                        <FaEuroSign style={{ marginRight: '4px', fontSize: '0.8rem' }} />
                        {total.toFixed(2)}
                      </Box>
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: { xs: '0.8rem', sm: '0.9rem' } }}>
                      {/* Removed "Total" text */}
                    </TableCell>
                  </TableRow>
                  {sortedExpenses.filter(e => e.payer === payer || (e.shared && e.sharedWith === payer) || (e.isDebt && e.debtFrom === payer)).map((expense, index) => (
                    <TableRow key={`${payer}-${expense.id}-${index}`} sx={{ backgroundColor: '#f9f9f9' }}>
                      <TableCell sx={{ fontSize: { xs: '0.7rem', sm: '0.8rem' } }}></TableCell>
                      <TableCell sx={{ fontSize: { xs: '0.7rem', sm: '0.8rem' } }}>{expense.category}</TableCell>
                      <TableCell sx={{ fontSize: { xs: '0.7rem', sm: '0.8rem' } }}>{expense.description}</TableCell>
                      <TableCell align="center" sx={{ fontSize: { xs: '0.7rem', sm: '0.8rem' } }}>
                        {new Date(expense.date).toLocaleDateString()}
                      </TableCell>
                      <TableCell align="right" sx={{ fontSize: { xs: '0.7rem', sm: '0.8rem' } }}>
                        {renderAmount(expense, payer)}
                      </TableCell>
                      <TableCell align="center" sx={{ fontSize: { xs: '0.7rem', sm: '0.8rem' } }}>
                        {renderExpenseType(expense)}
                      </TableCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Grid>
  );

  const renderExpenseType = (expense: Expense) => {
    const { text, tooltip } = getExpenseTypeDisplay(expense);
    return tooltip ? (
      <Tooltip title={tooltip}>
        <span>{text}</span>
      </Tooltip>
    ) : (
      text
    );
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom sx={{ fontSize: { xs: '1.1rem', sm: '1.25rem' } }}>
        <FormattedMessage id="total" />: 
        <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center', ml: 1 }}>
          <FaEuroSign style={{ marginRight: '4px' }} />
          {totalExpenses.toFixed(2)}
        </Box>
      </Typography>

      <Paper elevation={3} sx={{ p: { xs: 1, sm: 2 }, mb: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6} sm={2}>
            <FormControl fullWidth size="small">
              <InputLabel id="year-select-label"><FormattedMessage id="year" /></InputLabel>
              <Select
                labelId="year-select-label"
                value={selectedYear}
                label={intl.formatMessage({ id: "year" })}
                onChange={(e) => setSelectedYear(Number(e.target.value))}
                size="small"
              >
                {years.map((year) => (
                  <MenuItem key={year} value={year}>{year}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={2}>
            <FormControl fullWidth size="small">
              <InputLabel id="month-select-label"><FormattedMessage id="month" /></InputLabel>
              <Select
                labelId="month-select-label"
                value={showAllMonths ? 'all' : selectedMonth}
                label={intl.formatMessage({ id: "month" })}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value === 'all') {
                    setShowAllMonths(true);
                    setSelectedMonth(0);
                  } else {
                    setShowAllMonths(false);
                    setSelectedMonth(Number(value));
                  }
                }}
                size="small"
              >
                <MenuItem value="all"><FormattedMessage id="allMonths" /></MenuItem>
                {months.map((month) => (
                  <MenuItem key={month} value={month}>
                    {intl.formatMessage({ id: `month.${month}` })}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={2}>
            <FormControl fullWidth size="small">
              <InputLabel id="payer-select-label"><FormattedMessage id="payer" /></InputLabel>
              <Select
                labelId="payer-select-label"
                value={selectedPayer}
                label={intl.formatMessage({ id: "payer" })}
                onChange={handlePayerChange}
                size="small"
              >
                <MenuItem value=""><FormattedMessage id="allPayers" /></MenuItem>
                {sortedPayers.map(payer => (
                  <MenuItem key={payer} value={payer}>{payer}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={2}>
            <FormControl fullWidth size="small">
              <InputLabel id="category-select-label"><FormattedMessage id="category" /></InputLabel>
              <Select
                labelId="category-select-label"
                value={selectedCategory}
                label={intl.formatMessage({ id: "category" })}
                onChange={(e) => setSelectedCategory(e.target.value as string)}
                size="small"
              >
                <MenuItem value=""><FormattedMessage id="allCategories" /></MenuItem>
                {sortedCategories.map(category => (
                  <MenuItem key={category} value={category}>{category}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              size="small"
              placeholder={intl.formatMessage({ id: "search" })}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FaSearch />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box display="flex" justifyContent="space-between">
              <Tooltip title={intl.formatMessage({ id: "verticalView" })}>
                <IconButton onClick={() => setViewType('vertical')} color={viewType === 'vertical' ? 'primary' : 'default'} size="small">
                  <FaThList />
                </IconButton>
              </Tooltip>
              <Tooltip title={intl.formatMessage({ id: "horizontalView" })}>
                <IconButton onClick={() => setViewType('horizontal')} color={viewType === 'horizontal' ? 'primary' : 'default'} size="small">
                  <FaTable />
                </IconButton>
              </Tooltip>
              <Tooltip title={intl.formatMessage({ id: "expandAll" })}>
                <IconButton onClick={handleExpandAll} size="small">
                  <FaExpandAlt />
                </IconButton>
              </Tooltip>
              <Tooltip title={intl.formatMessage({ id: "collapseAll" })}>
                <IconButton onClick={handleCollapseAll} size="small">
                  <FaCompressAlt />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      {viewType === 'vertical' ? renderVerticalView() : renderHorizontalView()}
    </Box>
  );
};

export default MonthlyExpenses;