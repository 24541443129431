import React, { useState, useCallback, useEffect, ReactNode } from 'react';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import { Box, Drawer, List, ListItem, ListItemIcon, ListItemText, AppBar, Toolbar, Typography, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions, Select, MenuItem, FormControl, InputLabel, SelectChangeEvent, Grid, CircularProgress } from '@mui/material';
import { MoneyOff, TableChart, Dashboard, Description, AddCircle, Menu as MenuIcon, Settings as SettingsIcon, AttachMoney, ShowChart } from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { IntlProvider, FormattedMessage, useIntl } from 'react-intl';
import ptMessages from './locales/pt-PT.json';
import enMessages from './locales/en-US.json';
import ExpenseForm from './components/ExpenseForm';
import FilteredExpenses from './components/FilteredExpenses';
import MonthlyExpenses from './components/MonthlyExpenses';
import ExpenseReport from './components/ExpenseReport';
import DashboardComponent from './components/Dashboard';
import { Expense } from './types/Expense';
import { addExpense, getExpenses, updateExpense, deleteExpense, updateCategory, updatePayerColor, getPayerColors, initializePayerColors, updateIncomeCategory, expensesCache } from './firebase/firestoreOperations';
import { signOut, User } from "firebase/auth";
import { auth } from "./firebase/config";
import Login from './components/Login';
import { generateRandomColor } from './utils/colorUtils';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from './firebase/config';
import { SketchPicker } from 'react-color';
import { AuthProvider } from './contexts/AuthContext';
import { Income } from './types/Income';
import { addIncome, getIncome, updateIncome, deleteIncome, incomeCache } from './firebase/firestoreOperations';
import IncomeForm from './components/IncomeForm';
import IncomeList from './components/IncomeList';
import { AddCircle as AddCircleIcon } from '@mui/icons-material';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import InvestmentList from './components/InvestmentList';
import Sidebar from './components/Sidebar';
import { onSnapshot, query, where, collection } from "firebase/firestore";
import { Timestamp } from 'firebase/firestore';

// Definição dos temas
const themes = {
  default: {
    primary: { main: '#3498db' },
    secondary: { main: '#2ecc71' },
  },
  dark: {
    primary: { main: '#34495e' },
    secondary: { main: '#e74c3c' },
  },
  light: {
    primary: { main: '#757575' },
    secondary: { main: '#bdbdbd' },
  },
  nature: {
    primary: { main: '#27ae60' },
    secondary: { main: '#f39c12' },
  },
  ocean: {
    primary: { main: '#2980b9' },
    secondary: { main: '#e67e22' },
  },
  custom: {
    primary: { main: '#000000' },
    secondary: { main: '#ffffff' },
  },
};

type ThemeName = keyof typeof themes;

const drawerWidth = 240;

const messages: { [key: string]: any } = {
  'pt-PT': ptMessages,
  'en-US': enMessages,
};

interface AppProps {
  locale: 'pt-PT' | 'en-US';
  setLocale: (locale: 'pt-PT' | 'en-US') => void;
}

const App: React.FC<AppProps> = ({ locale, setLocale }) => {
  const intl = useIntl();
  const [expenses, setExpenses] = useState<Expense[]>([]);
  const [expenseCategories, setExpenseCategories] = useState<string[]>([]);
  const [incomeCategories, setIncomeCategories] = useState<string[]>([]);
  const [payers, setPayers] = useState<string[]>([]);
  const [visiblePayers, setVisiblePayers] = useState<string[]>([]);
  const [previousState, setPreviousState] = useState<{ expenses: Expense[], categories: string[], payers: string[] } | null>(null);
  const [activeTab, setActiveTab] = useState('add');
  const [mobileOpen, setMobileOpen] = useState(false);
  const [menuItems, setMenuItems] = useState<Array<{ id: string; text: string; icon: ReactNode; value: string; }>>([
    { id: 'dashboard', text: intl.formatMessage({ id: 'menu.dashboard' }), icon: <Dashboard />, value: 'dashboard' },
    { id: 'add', text: intl.formatMessage({ id: 'menu.add' }), icon: <AddCircle />, value: 'add' },
    { id: 'monthly', text: intl.formatMessage({ id: 'menu.monthly' }), icon: <MoneyOff />, value: 'monthly' },
    { id: 'all', text: intl.formatMessage({ id: 'menu.all' }), icon: <TableChart />, value: 'all' },
    { id: 'report', text: intl.formatMessage({ id: 'menu.report' }), icon: <Description />, value: 'report' },
    { id: 'income', text: intl.formatMessage({ id: 'menu.income' }), icon: <AttachMoney />, value: 'income' },
    { id: 'investments', text: intl.formatMessage({ id: 'menu.investments' }), icon: <ShowChart />, value: 'investments' },
  ]);
  const [openSettings, setOpenSettings] = useState(false);
  const [themeMode, setThemeMode] = useState<'light' | 'dark' | 'system'>(() => {
    return localStorage.getItem('themeMode') as 'light' | 'dark' | 'system' || 'light';
  });
  const [themeName, setThemeName] = useState<ThemeName>(() => {
    return localStorage.getItem('themeName') as ThemeName || 'default';
  });
  const [customPrimaryColor, setCustomPrimaryColor] = useState(() => {
    return localStorage.getItem('customPrimaryColor') || '#000000';
  });
  const [customSecondaryColor, setCustomSecondaryColor] = useState(() => {
    return localStorage.getItem('customSecondaryColor') || '#ffffff';
  });
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [key, setKey] = useState(0);
  const [payerColors, setPayerColors] = useState<{ [key: string]: string }>({});
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [authError, setAuthError] = useState<string | null>(null);
  const [income, setIncome] = useState<Income[]>([]);
  const [filterYear, setFilterYear] = useState<number>(new Date().getFullYear());
  const [filterMonth, setFilterMonth] = useState<number | 'all'>('all');
  const [filterPayer, setFilterPayer] = useState<string>('');

  useEffect(() => {
    console.log("Setting up auth state listener");
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID
    };
    console.log("Firebase config:", firebaseConfig);

    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      console.log("Auth state changed", currentUser);
      setUser(currentUser);
      setLoading(false);
      if (currentUser) {
        console.log("User authenticated, fetching data");
        setActiveTab('add');
        setOpenSettings(false);
        fetchUserData(currentUser);
        fetchIncome(currentUser);
      } else {
        console.log("No user authenticated");
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchUserData = async (user: User) => {
    try {
      console.log("Fetching user data for:", user.uid);
      await initializePayerColors();
      const fetchedExpenses = await getExpenses(user);
      const fetchedIncome = await getIncome(user);
      setExpenses(fetchedExpenses);
      setIncome(fetchedIncome);
      
      const expenseCategories = new Set(fetchedExpenses.map(e => e.category));
      const incomeCategories = new Set(fetchedIncome.map(i => i.category));
      setExpenseCategories(Array.from(expenseCategories));
      setIncomeCategories(Array.from(incomeCategories));
      
      const uniquePayers = Array.from(new Set(fetchedExpenses.flatMap(e => [e.payer, e.sharedWith]).filter((payer): payer is string => Boolean(payer))));
      setPayers(uniquePayers);
      setVisiblePayers(uniquePayers);

      const colors = await getPayerColors();
      setPayerColors(colors);
      console.log("User data fetched successfully");
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error instanceof Error) {
        setAuthError(`Failed to fetch user data: ${error.message}`);
      } else {
        setAuthError("Failed to fetch user data: Unknown error");
      }
    }
  };

  const fetchIncome = async (user: User) => {
    try {
      console.log("Fetching income for user:", user.uid);
      if (!user) {
        console.error("No authenticated user");
        setAuthError("No authenticated user");
        return;
      }
      const fetchedIncome = await getIncome(user);
      console.log("Fetched income:", JSON.stringify(fetchedIncome));
      setIncome(fetchedIncome);
    } catch (error) {
      console.error("Error fetching income:", error);
      if (error instanceof Error) {
        console.error("Error name:", error.name);
        console.error("Error message:", error.message);
        console.error("Error stack:", error.stack);
        setAuthError(`Failed to fetch income data: ${error.message}`);
      } else {
        console.error("Unknown error type:", error);
        setAuthError("Failed to fetch income data: Unknown error");
      }
    }
  };

  const addExpenseHandler = useCallback(async (expense: Omit<Expense, 'id' | 'userId'>, file?: File) => {
    if (user) {
      try {
        const newExpense = await addExpense({ ...expense, userId: user.uid }, user, file);
        setExpenses(prevExpenses => [...prevExpenses, newExpense]);
        setExpenseCategories(prevCategories => 
          prevCategories.includes(newExpense.category) 
            ? prevCategories 
            : [...prevCategories, newExpense.category]
        );

        if (!payers.includes(newExpense.payer)) {
          setPayers(prevPayers => [...prevPayers, newExpense.payer]);
          setVisiblePayers(prevVisible => [...prevVisible, newExpense.payer]);
        }

        if (newExpense.shared && newExpense.sharedWith && !payers.includes(newExpense.sharedWith)) {
          setPayers(prevPayers => [...prevPayers, newExpense.sharedWith!]);
          setVisiblePayers(prevVisible => [...prevVisible, newExpense.sharedWith!]);
        }

        console.log("Expense added successfully:", newExpense);
      } catch (error) {
        console.error("Error in addExpenseHandler:", error);
        alert(`Failed to add expense: ${error instanceof Error ? error.message : 'Unknown error'}`);
      }
    }
  }, [payers, user]);

  const editExpenseHandler = useCallback(async (editedExpense: Expense) => {
    if (user) {
      try {
        await updateExpense(editedExpense, user);
        setExpenses(prevExpenses => prevExpenses.map(expense => 
          expense.id === editedExpense.id ? editedExpense : expense
        ));

        if (!expenseCategories.includes(editedExpense.category)) {
          setExpenseCategories(prevCategories => [...prevCategories, editedExpense.category]);
        }

        const newPayers = new Set(payers);
        newPayers.add(editedExpense.payer);
        if (editedExpense.shared && editedExpense.sharedWith) {
          newPayers.add(editedExpense.sharedWith);
        }
        setPayers(Array.from(newPayers));

        if (editedExpense.shared && editedExpense.sharedWith) {
          const sharedExpense: Omit<Expense, 'id'> = {
            ...editedExpense,
            payer: editedExpense.sharedWith,
            sharedWith: editedExpense.payer,
            amount: editedExpense.amount / 2,
          };
          
          const existingSharedExpense = expenses.find(e => 
            e.date === editedExpense.date && 
            e.category === editedExpense.category && 
            e.description === editedExpense.description && 
            e.payer === editedExpense.sharedWith &&
            e.sharedWith === editedExpense.payer
          );

          if (existingSharedExpense) {
            await updateExpense({...sharedExpense, id: existingSharedExpense.id}, user);
            setExpenses(prevExpenses => prevExpenses.map(expense => 
              expense.id === existingSharedExpense.id ? {...sharedExpense, id: existingSharedExpense.id} : expense
            ));
          }
        }

      } catch (error) {
        console.error("Error editing expense:", error);
        alert(`Failed to edit expense: ${error instanceof Error ? error.message : 'Unknown error'}`);
      }
    }
  }, [expenseCategories, payers, expenses, user]);

  const deleteExpenseHandler = useCallback(async (id: string) => {
    if (user) {
      try {
        await deleteExpense(id, user);
        setExpenses(prevExpenses => prevExpenses.filter(expense => expense.id !== id));
        console.log("Expense deleted successfully:", id);
      } catch (error) {
        console.error("Error in deleteExpenseHandler:", error);
        alert(`Failed to delete expense: ${error instanceof Error ? error.message : 'Unknown error'}`);
      }
    }
  }, [user]);

  const addExpenseCategory = useCallback((newCategory: string) => {
    setExpenseCategories(prevCategories => 
      prevCategories.includes(newCategory) 
        ? prevCategories 
        : [...prevCategories, newCategory]
    );
  }, []);

  const addIncomeCategory = useCallback((newCategory: string) => {
    setIncomeCategories(prevCategories => 
      prevCategories.includes(newCategory) 
        ? prevCategories 
        : [...prevCategories, newCategory]
    );
  }, []);

  const editExpenseCategory = useCallback(async (oldCategory: string, newCategory: string) => {
    try {
      await updateCategory(oldCategory, newCategory);
      setExpenseCategories(prevCategories => {
        const updatedCategories = prevCategories.filter(c => c !== oldCategory);
        return [...updatedCategories, newCategory].sort();
      });
      setExpenses(prevExpenses => 
        prevExpenses.map(expense => 
          expense.category === oldCategory ? { ...expense, category: newCategory } : expense
        )
      );
    } catch (error) {
      console.error("Error updating category:", error);
    }
  }, []);

  const deleteExpenseCategory = useCallback((categoryToDelete: string) => {
    setExpenseCategories(prevCategories => prevCategories.filter(c => c !== categoryToDelete));
    setExpenses(prevExpenses => prevExpenses.map(expense => 
      expense.category === categoryToDelete ? { ...expense, category: 'Uncategorized' } : expense
    ));
  }, []);

  const addPayer = useCallback((newPayer: string) => {
    const trimmedPayer = newPayer.trim();
    if (!payers.includes(trimmedPayer)) {
      setPreviousState({ expenses, categories: expenseCategories, payers });
      setPayers(prevPayers => [...prevPayers, trimmedPayer]);
      setVisiblePayers(prevVisible => [...prevVisible, trimmedPayer]);
      
      const newColor = generateRandomColor();
      setPayerColors(prevColors => ({ ...prevColors, [trimmedPayer]: newColor }));
      
      updatePayerColor(trimmedPayer, newColor);
    } else {
      alert(intl.formatMessage({ id: 'payerExists' }, { payer: trimmedPayer }));
    }
  }, [expenses, expenseCategories, payers, intl]);

  const editPayer = useCallback((oldPayer: string, newPayer: string) => {
    const trimmedNewPayer = newPayer.trim();
    if (oldPayer !== trimmedNewPayer && !payers.includes(trimmedNewPayer)) {
      setPreviousState({ expenses, categories: expenseCategories, payers });
      setPayers(prevPayers => 
        prevPayers.map(p => p === oldPayer ? trimmedNewPayer : p)
      );
      setVisiblePayers(prevVisible => 
        prevVisible.map(p => p === oldPayer ? trimmedNewPayer : p)
      );
      setExpenses(prevExpenses => 
        prevExpenses.map(expense => ({
          ...expense,
          payer: expense.payer === oldPayer ? trimmedNewPayer : expense.payer,
          sharedWith: expense.sharedWith === oldPayer ? trimmedNewPayer : expense.sharedWith
        }))
      );
    } else if (payers.includes(trimmedNewPayer)) {
      alert(intl.formatMessage({ id: 'payerExists' }, { payer: trimmedNewPayer }));
    }
  }, [expenses, expenseCategories, payers, intl]);

  const deletePayer = useCallback((payerToDelete: string) => {
    if (payers.length <= 1) {
      alert(intl.formatMessage({ id: 'cantDeleteLastPayer' }));
      return;
    }
    if (window.confirm(intl.formatMessage({ id: 'confirmDeletePayer' }, { payer: payerToDelete }))) {
      setPreviousState({ expenses, categories: expenseCategories, payers });
      setPayers(prevPayers => prevPayers.filter(p => p !== payerToDelete));
      setVisiblePayers(prevVisible => prevVisible.filter(p => p !== payerToDelete));
      setExpenses(prevExpenses => 
        prevExpenses.map(expense => {
          if (expense.payer === payerToDelete) {
            return { ...expense, payer: payers[0] };
          }
          if (expense.sharedWith === payerToDelete) {
            return { ...expense, sharedWith: payers[0] === payerToDelete ? payers[1] : payers[0] };
          }
          return expense;
        })
      );
    }
  }, [expenses, expenseCategories, payers, intl]);

  const handleClearAllData = () => {
    if (window.confirm(intl.formatMessage({ id: 'confirmClearData' }))) {
      setPreviousState({ expenses, categories: expenseCategories, payers });
      setExpenses([]);
      setExpenseCategories([]);
      setPayers([]);
      setVisiblePayers([]);
    }
  };

  const handleUndo = () => {
    if (previousState) {
      setExpenses(previousState.expenses);
      setExpenseCategories(previousState.categories);
      setPayers(previousState.payers);
      setPreviousState(null);
    }
  };

  const togglePayerVisibility = useCallback((payer: string) => {
    setVisiblePayers(prevVisible => 
      prevVisible.includes(payer)
        ? prevVisible.filter(p => p !== payer)
        : [...prevVisible, payer]
    );
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(menuItems);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setMenuItems(items);
    localStorage.setItem('menuOrder', JSON.stringify(items.map(item => item.id)));
  };

  const handleOpenSettings = () => {
    setOpenSettings(true);
  };

  const handleCloseSettings = () => {
    setOpenSettings(false);
  };

  const handleThemeChange = (event: SelectChangeEvent<'light' | 'dark' | 'system'>) => {
    const newThemeMode = event.target.value as 'light' | 'dark' | 'system';
    setThemeMode(newThemeMode);
    localStorage.setItem('themeMode', newThemeMode);
  };

  const handleThemeNameChange = (event: SelectChangeEvent<ThemeName>) => {
    const newThemeName = event.target.value as ThemeName;
    setThemeName(newThemeName);
    localStorage.setItem('themeName', newThemeName);
  };

  const handleCustomColorChange = (color: string, type: 'primary' | 'secondary') => {
    if (type === 'primary') {
      setCustomPrimaryColor(color);
      localStorage.setItem('customPrimaryColor', color);
    } else {
      setCustomSecondaryColor(color);
      localStorage.setItem('customSecondaryColor', color);
    }
  };

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: themeMode === 'system' ? (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light') : themeMode,
          primary: themeName === 'custom' 
            ? { main: customPrimaryColor } 
            : themes[themeName].primary,
          secondary: themeName === 'custom' 
            ? { main: customSecondaryColor } 
            : themes[themeName].secondary,
        },
      }),
    [themeMode, themeName, customPrimaryColor, customSecondaryColor]
  );

  const drawer = (
    <div>
      <Toolbar />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="menu">
          {(provided) => (
            <List {...provided.droppableProps} ref={provided.innerRef}>
              {menuItems.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <ListItem
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      button
                      onClick={() => setActiveTab(item.value)}
                      selected={activeTab === item.value}
                    >
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={intl.formatMessage({ id: `menu.${item.id}` })} />
                    </ListItem>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );

  useEffect(() => {
    const savedOrder = localStorage.getItem('menuOrder');
    if (savedOrder) {
      const orderIds = JSON.parse(savedOrder) as string[];
      const orderedItems = orderIds
        .map(id => menuItems.find(item => item.id === id))
        .filter(Boolean) as typeof menuItems;
      if (orderedItems.length === menuItems.length) {
        setMenuItems(orderedItems);
      }
    }
  }, []);

  useEffect(() => {
    setMenuItems(prevItems => prevItems.map(item => ({
      ...item,
      text: intl.formatMessage({ id: `menu.${item.id}` })
    })));
  }, [intl]);

  useEffect(() => {
    setKey(prevKey => prevKey + 1);
  }, [locale]);

  const editPayerColorHandler = useCallback(async (payer: string, color: string) => {
    try {
      await updatePayerColor(payer, color);
      setPayerColors(prev => ({ ...prev, [payer]: color }));
    } catch (error) {
      console.error("Error updating payer color:", error);
      alert(`Failed to update payer color: ${error instanceof Error ? error.message : 'Unknown error'}`);
    }
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUser(null);
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  useEffect(() => {
    if (user) {
      loadUserSettings();
    }
  }, [user]);

  const loadUserSettings = () => {
    const savedThemeMode = localStorage.getItem('themeMode') as 'light' | 'dark' | 'system';
    const savedThemeName = localStorage.getItem('themeName') as ThemeName;
    const savedCustomPrimaryColor = localStorage.getItem('customPrimaryColor');
    const savedCustomSecondaryColor = localStorage.getItem('customSecondaryColor');

    if (savedThemeMode) setThemeMode(savedThemeMode);
    if (savedThemeName) setThemeName(savedThemeName);
    if (savedCustomPrimaryColor) setCustomPrimaryColor(savedCustomPrimaryColor);
    if (savedCustomSecondaryColor) setCustomSecondaryColor(savedCustomSecondaryColor);
  };

  const saveUserSettings = () => {
    localStorage.setItem('themeMode', themeMode);
    localStorage.setItem('themeName', themeName);
    localStorage.setItem('customPrimaryColor', customPrimaryColor);
    localStorage.setItem('customSecondaryColor', customSecondaryColor);
  };

  const handleUpdateExpense = async (updatedExpense: Expense, file?: File) => {
    if (user) {
      try {
        await updateExpense(updatedExpense, user, file);
        setExpenses(prevExpenses => 
          prevExpenses.map(expense => 
            expense.id === updatedExpense.id ? updatedExpense : expense
          )
        );
      } catch (error) {
        console.error("Error updating expense:", error);
        alert(intl.formatMessage({ id: 'errorUpdatingExpense' }));
      }
    }
  };

  const addIncomeHandler = useCallback(async (incomeEntry: Omit<Income, 'id' | 'userId'>, file?: File) => {
    if (user) {
      try {
        const newIncome = await addIncome(incomeEntry, user.uid, file);
        setIncome(prevIncome => [...prevIncome, newIncome]);
        setIncomeCategories(prevCategories => 
          prevCategories.includes(newIncome.category) 
            ? prevCategories 
            : [...prevCategories, newIncome.category]
        );
      } catch (error) {
        console.error("Error in addIncomeHandler:", error);
        alert(`Failed to add income: ${error instanceof Error ? error.message : 'Unknown error'}`);
      }
    }
  }, [user, incomeCategories]);

  const editIncomeHandler = useCallback(async (updatedIncome: Income): Promise<void> => {
    if (user) {
      try {
        await updateIncome(updatedIncome, user);
        setIncome(prevIncome => prevIncome.map(inc => inc.id === updatedIncome.id ? updatedIncome : inc));
      } catch (error) {
        console.error("Error in editIncomeHandler:", error);
        alert(`Failed to update income: ${error instanceof Error ? error.message : 'Unknown error'}`);
      }
    }
  }, [user]);

  const deleteIncomeHandler = useCallback(async (id: string) => {
    if (user) {
      try {
        await deleteIncome(id, user);
        setIncome(prevIncome => prevIncome.filter(inc => inc.id !== id));
      } catch (error) {
        console.error("Error in deleteIncomeHandler:", error);
        alert(`Failed to delete income: ${error instanceof Error ? error.message : 'Unknown error'}`);
      }
    }
  }, [user]);

  const editIncomeCategoryHandler = useCallback(async (oldCategory: string, newCategory: string) => {
    if (user) {
      try {
        // Atualizar a categoria no Firestore (você precisa implementar esta função)
        await updateIncomeCategory(oldCategory, newCategory, user);
        
        // Atualizar o estado local
        setIncomeCategories(prevCategories => {
          const updatedCategories = prevCategories.filter(c => c !== oldCategory);
          return [...updatedCategories, newCategory].sort();
        });
        
        setIncome(prevIncome => 
          prevIncome.map(inc => 
            inc.category === oldCategory ? { ...inc, category: newCategory } : inc
          )
        );
      } catch (error) {
        console.error("Error updating income category:", error);
        alert(`Failed to update income category: ${error instanceof Error ? error.message : 'Unknown error'}`);
      }
    }
  }, [user]);

  useEffect(() => {
    console.log('Current menuItems:', menuItems);
  }, [menuItems]);

  useEffect(() => {
    if (user) {
      const expensesQuery = query(collection(db, "expenses"), where("userId", "==", user.uid));
      const unsubscribeExpenses = onSnapshot(expensesQuery, (snapshot) => {
        const updatedExpenses = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          date: doc.data().date.toDate().toISOString().split('T')[0]
        } as Expense));
        setExpenses(updatedExpenses);
        expensesCache.set(user.uid, updatedExpenses);
        console.log("Expenses updated from Firestore:", updatedExpenses);
      });

      const incomeQuery = query(collection(db, 'income'), where("userId", "==", user.uid));
      const unsubscribeIncome = onSnapshot(incomeQuery, (snapshot) => {
        const updatedIncome = snapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
          date: doc.data().date instanceof Timestamp ? doc.data().date.toDate().toISOString().split('T')[0] : doc.data().date,
        } as Income));
        setIncome(updatedIncome);
        incomeCache.set(user.uid, updatedIncome);
        console.log("Income updated from Firestore:", updatedIncome);
      });

      return () => {
        unsubscribeExpenses();
        unsubscribeIncome();
      };
    }
  }, [user]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!user) {
    console.log("No user, showing login screen");
    return <Login setUser={setUser} />;
  }

  console.log("User authenticated, rendering main app");
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box sx={{ display: 'flex' }} key={key}>
          <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
              <MoneyOff sx={{ mr: 2 }} />
              <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                <FormattedMessage id="app.title" />
              </Typography>
              <Typography variant="subtitle1" sx={{ mr: 2 }}>
                {user.displayName}
              </Typography>
              <IconButton color="inherit" onClick={handleOpenSettings}>
                <SettingsIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Sidebar
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            menuItems={menuItems}
            setMenuItems={setMenuItems}
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
            drawerWidth={drawerWidth}
          />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              marginLeft: { sm: `${drawerWidth}px` },
              marginTop: '64px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              minHeight: 'calc(100vh - 64px)',
            }}
          >
            <Box sx={{ width: '100%', maxWidth: '1200px' }}>
              <AnimatePresence mode="wait">
                <motion.div
                  key={activeTab}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                  style={{ width: '100%' }}
                >
                  {activeTab === 'dashboard' && (
                    <DashboardComponent 
                      expenses={expenses.filter(e => e.userId === user.uid)} 
                      income={income.filter(i => i.userId === user.uid)}
                      payers={payers}
                      payerColors={payerColors}
                    />
                  )}
                  {activeTab === 'add' && (
                    <>
                      <Typography variant="h5" gutterBottom>
                        <FormattedMessage id="addExpense.title" />
                      </Typography>
                      <ExpenseForm 
                        onAddExpense={addExpenseHandler} 
                        categories={expenseCategories} 
                        payers={payers}
                        onAddCategory={addExpenseCategory}
                        onEditCategory={editExpenseCategory}
                        onDeleteCategory={deleteExpenseCategory}
                        onAddPayer={addPayer}
                        onEditPayer={editPayer}
                        onDeletePayer={deletePayer}
                        onEditPayerColor={editPayerColorHandler}
                        payerColors={payerColors}
                      />
                    </>
                  )}
                  {activeTab === 'all' && (
                    <>
                      <Typography variant="h5" gutterBottom>
                        <FormattedMessage id="allExpenses.title" />
                      </Typography>
                      <FilteredExpenses 
                        expenses={expenses}
                        filter={{
                          year: filterYear,
                          month: filterMonth,
                          payer: filterPayer
                        }}
                        payers={payers}
                        categories={expenseCategories}
                        onEditExpense={handleUpdateExpense}
                        onDeleteExpense={deleteExpenseHandler}
                        payerColors={payerColors}
                      />
                    </>
                  )}
                  {activeTab === 'monthly' && (
                    <>
                      <Typography variant="h5" gutterBottom>
                        <FormattedMessage id="monthlyExpenses.title" />
                      </Typography>
                      <MonthlyExpenses 
                        expenses={expenses.filter(e => e.userId === user.uid)} 
                        payers={payers} 
                        categories={expenseCategories}
                        payerColors={payerColors}
                      />
                    </>
                  )}
                  {activeTab === 'report' && (
                    <>
                      <Typography variant="h5" gutterBottom>
                        <FormattedMessage id="createReport" />
                      </Typography>
                      <Box sx={{ width: '100%', maxWidth: 800, margin: '0 auto' }}>
                        <ExpenseReport 
                          expenses={expenses.filter(e => e.userId === user.uid)}
                          payers={payers}
                          payerColors={payerColors}
                          onUpdateExpense={handleUpdateExpense}
                        />
                      </Box>
                    </>
                  )}
                  {activeTab === 'income' && (
                    <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="h5" gutterBottom>
                        <FormattedMessage id="income.title" />
                      </Typography>
                      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                        <IncomeForm
                          onAddIncome={addIncomeHandler}
                          categories={incomeCategories}
                          onAddCategory={addIncomeCategory}
                          onEditCategory={editIncomeCategoryHandler}
                        />
                        <IncomeList
                          income={income}
                          onEditIncome={editIncomeHandler}
                          onDeleteIncome={deleteIncomeHandler}
                          categories={incomeCategories}
                          onAddCategory={addIncomeCategory}
                          onEditCategory={editIncomeCategoryHandler}
                        />
                      </Box>
                    </Box>
                  )}
                  {activeTab === 'investments' && (
                    <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="h5" gutterBottom>
                        <FormattedMessage id="investments.title" />
                      </Typography>
                      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                        <InvestmentList
                          user={user}
                        />
                      </Box>
                    </Box>
                  )}
                </motion.div>
              </AnimatePresence>
            </Box>
          </Box>

          {openSettings && (
            <Dialog open={openSettings} onClose={handleCloseSettings}>
              <DialogTitle>
                <Typography variant="h5">
                  <FormattedMessage id="settings" />
                </Typography>
              </DialogTitle>
              <DialogContent>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="language-select-label">
                    <FormattedMessage id="language" />
                  </InputLabel>
                  <Select
                    labelId="language-select-label"
                    value={locale}
                    onChange={(e) => setLocale(e.target.value as 'pt-PT' | 'en-US')}
                    label={<FormattedMessage id="language" />}
                  >
                    <MenuItem value="pt-PT">Português</MenuItem>
                    <MenuItem value="en-US">English</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="theme-mode-select-label"><FormattedMessage id="themeMode" /></InputLabel>
                  <Select
                    labelId="theme-mode-select-label"
                    value={themeMode}
                    onChange={handleThemeChange}
                    label={<FormattedMessage id="themeMode" />}
                  >
                    <MenuItem value="light"><FormattedMessage id="lightMode" /></MenuItem>
                    <MenuItem value="dark"><FormattedMessage id="darkMode" /></MenuItem>
                    <MenuItem value="system"><FormattedMessage id="systemMode" /></MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="theme-name-select-label"><FormattedMessage id="themeName" /></InputLabel>
                  <Select
                    labelId="theme-name-select-label"
                    value={themeName}
                    onChange={handleThemeNameChange}
                    label={<FormattedMessage id="themeName" />}
                  >
                    <MenuItem value="default"><FormattedMessage id="defaultTheme" /></MenuItem>
                    <MenuItem value="dark"><FormattedMessage id="darkTheme" /></MenuItem>
                    <MenuItem value="light"><FormattedMessage id="lightTheme" /></MenuItem>
                    <MenuItem value="nature"><FormattedMessage id="natureTheme" /></MenuItem>
                    <MenuItem value="ocean"><FormattedMessage id="oceanTheme" /></MenuItem>
                    <MenuItem value="custom"><FormattedMessage id="customTheme" /></MenuItem>
                  </Select>
                </FormControl>
                {themeName === 'custom' && (
                  <Box mt={2}>
                    <Button 
                      onClick={() => setShowColorPicker(!showColorPicker)}
                      variant="outlined"
                      fullWidth
                    >
                      <FormattedMessage id="chooseCustomColors" />
                    </Button>
                    {showColorPicker && (
                      <Box mt={2}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <Typography><FormattedMessage id="primaryColor" /></Typography>
                            <Box mt={1} mb={2}>
                              <SketchPicker
                                color={customPrimaryColor}
                                onChangeComplete={(color) => handleCustomColorChange(color.hex, 'primary')}
                                disableAlpha
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography><FormattedMessage id="secondaryColor" /></Typography>
                            <Box mt={1} mb={2}>
                              <SketchPicker
                                color={customSecondaryColor}
                                onChangeComplete={(color) => handleCustomColorChange(color.hex, 'secondary')}
                                disableAlpha
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                  </Box>
                )}
                <Box mt={2}>
                  <Button onClick={handleClearAllData} color="error" fullWidth>
                    <FormattedMessage id="clearAllData" />
                  </Button>
                </Box>
                <Box mt={2}>
                  <Button onClick={handleUndo} color="primary" fullWidth disabled={!previousState}>
                    <FormattedMessage id="undo" />
                  </Button>
                </Box>
                <Box mt={2}>
                  <Button onClick={handleLogout} color="error" fullWidth>
                    <FormattedMessage id="logout" />
                  </Button>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseSettings} color="primary">
                  <FormattedMessage id="close" />
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </Box>
      </ThemeProvider>
    </Router>
  );
};

export default App;