import React, { useState, useMemo, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, TextField, TableSortLabel, Avatar, CircularProgress } from '@mui/material';
import { Edit, Delete, Description, CalendarToday, AttachMoney, Category, Source, Repeat, InsertDriveFile } from '@mui/icons-material';
import { Income } from '../types/Income';
import { FormattedMessage } from 'react-intl';
import IncomeForm from './IncomeForm';
import { getTokenDetails } from '../services/coingeckoService';

interface IncomeListProps {
  income: Income[];
  onEditIncome: (updatedIncome: Income) => Promise<void>;
  onDeleteIncome: (id: string) => Promise<void>;
  categories: string[];
  onAddCategory: (category: string) => void;
  onEditCategory: (oldCategory: string, newCategory: string) => void;
}

type SortField = 'date' | 'description' | 'amount' | 'category' | 'source';
type SortOrder = 'asc' | 'desc';

const IncomeList: React.FC<IncomeListProps> = ({ income, onEditIncome, onDeleteIncome, categories, onAddCategory, onEditCategory }) => {
  const [editingIncome, setEditingIncome] = useState<Income | null>(null);
  const [viewingReceipt, setViewingReceipt] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState<SortField>('date');
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
  const [tokenDetails, setTokenDetails] = useState<{ [key: string]: { logo: string, currentPrice: number } }>({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTokenDetails = async () => {
      const tokens = [...new Set(income.map(entry => entry.token))];
      const details: { [key: string]: { logo: string, currentPrice: number } } = {};

      for (const token of tokens) {
        try {
          const tokenInfo = await getTokenDetails(token);
          if (tokenInfo) {
            details[token] = {
              logo: tokenInfo.image,
              currentPrice: tokenInfo.current_price
            };
          }
        } catch (error) {
          console.error(`Error fetching details for ${token}:`, error);
        }
      }

      setTokenDetails(details);
      setIsLoading(false);
    };

    fetchTokenDetails();
  }, [income]);

  const handleEditClick = (incomeItem: Income) => {
    setEditingIncome(incomeItem);
  };

  const handleEditClose = () => {
    setEditingIncome(null);
  };

  const handleEditSubmit = async (updatedIncome: Omit<Income, 'id' | 'userId'>): Promise<void> => {
    if (editingIncome) {
      await onEditIncome({ ...updatedIncome, id: editingIncome.id, userId: editingIncome.userId });
      setEditingIncome(null);
    }
  };

  const handleViewReceipt = (fileUrl: string | undefined) => {
    if (fileUrl) {
      setViewingReceipt(fileUrl);
    }
  };

  const handleCloseReceipt = () => {
    setViewingReceipt(null);
  };

  const handleSort = (field: SortField) => {
    if (field === sortField) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  const sortedIncome = useMemo(() => {
    return income
      .filter(entry =>
        entry.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        entry.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
        entry.source.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort((a, b) => {
        const aValue = a[sortField as keyof Income] ?? '';
        const bValue = b[sortField as keyof Income] ?? '';
        if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
        return 0;
      });
  }, [income, searchTerm, sortField, sortOrder]);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Typography variant="h6" sx={{ p: 2 }}><FormattedMessage id="incomeList" /></Typography>
        <Box sx={{ p: 2 }}>
          <TextField
            fullWidth
            label={<FormattedMessage id="search" />}
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sortField === 'date'}
                  direction={sortField === 'date' ? sortOrder : 'asc'}
                  onClick={() => handleSort('date')}
                >
                  <FormattedMessage id="date" />
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === 'description'}
                  direction={sortField === 'description' ? sortOrder : 'asc'}
                  onClick={() => handleSort('description')}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Description sx={{ mr: 1 }} />
                    <FormattedMessage id="description" />
                  </Box>
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === 'amount'}
                  direction={sortField === 'amount' ? sortOrder : 'asc'}
                  onClick={() => handleSort('amount')}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <AttachMoney sx={{ mr: 1 }} />
                    <FormattedMessage id="amount" />
                  </Box>
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === 'category'}
                  direction={sortField === 'category' ? sortOrder : 'asc'}
                  onClick={() => handleSort('category')}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Category sx={{ mr: 1 }} />
                    <FormattedMessage id="category" />
                  </Box>
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === 'source'}
                  direction={sortField === 'source' ? sortOrder : 'asc'}
                  onClick={() => handleSort('source')}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Source sx={{ mr: 1 }} />
                    <FormattedMessage id="source" />
                  </Box>
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <FormattedMessage id="actions" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedIncome.map((entry) => (
              <TableRow key={entry.id}>
                <TableCell>{new Date(entry.date).toLocaleDateString()}</TableCell>
                <TableCell>{entry.description}</TableCell>
                <TableCell>€{entry.amount.toFixed(2)} {entry.token}</TableCell>
                <TableCell>{entry.category}</TableCell>
                <TableCell>{entry.source}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEditClick(entry)}>
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => onDeleteIncome(entry.id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={!!editingIncome} onClose={handleEditClose}>
        <DialogTitle><FormattedMessage id="editIncome" /></DialogTitle>
        <DialogContent>
          {editingIncome && (
            <IncomeForm
              onAddIncome={handleEditSubmit}
              categories={categories}
              onAddCategory={onAddCategory}
              onEditCategory={onEditCategory}
              initialIncome={editingIncome}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose}><FormattedMessage id="cancel" /></Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!viewingReceipt} onClose={handleCloseReceipt}>
        <DialogTitle><FormattedMessage id="viewReceipt" /></DialogTitle>
        <DialogContent>
          {viewingReceipt && (
            <img src={viewingReceipt} alt="Receipt" style={{ maxWidth: '100%', maxHeight: '80vh' }} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReceipt}><FormattedMessage id="close" /></Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default IncomeList;