import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Expense } from '../types/Expense';
import { Income } from '../types/Income';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip, Legend, BarChart as RechartsBarChart, Bar, XAxis, YAxis, CartesianGrid, LineChart, Line, AreaChart, Area } from 'recharts';
import { format } from 'date-fns';
import { Box, Card, CardContent, Grid, Typography, useTheme, LinearProgress, Select, MenuItem, FormControl, InputLabel, TextField, IconButton, Icon, Switch, FormControlLabel, CircularProgress, SelectChangeEvent } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import WidgetSelector from './WidgetSelector';
import { ArrowUpward, BarChart, ArrowDownward, Dashboard as DashboardIcon, TrendingUp, TrendingDown } from '@mui/icons-material';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db, auth } from '../firebase/firebase';
import CategoryExpenseChart from './CategoryExpenseChart';
import FilteredExpenses from './FilteredExpenses';
import { Tooltip as MuiTooltip } from '@mui/material';

interface DashboardProps {
  expenses: Expense[];
  income: Income[];
  payers: string[];
  payerColors: { [key: string]: string };
}

interface FilteredExpensesProps {
  expenses: Expense[];
  filter: {
    year: number;
    month: number | "all";
    payer: string;
  };
  payers: string[];
  categories: string[];
  onEditExpense: (editedExpense: Expense, file?: File) => Promise<void>;
  onDeleteExpense: (id: string) => void;
  payerColors: { [key: string]: string };
}

const CHART_COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8', '#82CA9D'];

const Dashboard: React.FC<DashboardProps> = ({ expenses, income, payers, payerColors }) => {
  const theme = useTheme();
  const intl = useIntl();
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState<number | 'all'>(new Date().getMonth());
  const [selectedPayer, setSelectedPayer] = useState<string>('');
  const [openWidgetSelector, setOpenWidgetSelector] = useState(false);
  const [selectedWidgets, setSelectedWidgets] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [chartType, setChartType] = useState<'area' | 'bar'>('area');

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      await loadSelectedWidgets();
      setIsLoading(false);
    };

    loadData();
  }, []);

  const loadSelectedWidgets = async () => {
    if (auth.currentUser) {
      const userDocRef = doc(db, 'userSettings', auth.currentUser.uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists() && userDoc.data().selectedWidgets) {
        setSelectedWidgets(userDoc.data().selectedWidgets);
      } else {
        setSelectedWidgets([
          'totalIncome', 'totalExpenses', 'balance', 'savingsRate',
          'incomeVsExpenses', 'expenseCategories',
          'expenseToIncomeRatio'
        ]);
      }
    }
  };

  useEffect(() => {
    const saveSelectedWidgets = async () => {
      if (auth.currentUser) {
        const userDocRef = doc(db, 'userSettings', auth.currentUser.uid);
        await setDoc(userDocRef, { selectedWidgets }, { merge: true });
      }
    };

    if (selectedWidgets.length > 0) {
      saveSelectedWidgets();
    }
  }, [selectedWidgets]);

  const getFilteredData = useCallback((year: number, month: number | 'all') => {
    const filterDate = (date: Date) => {
      if (month === 'all') {
        return date.getFullYear() === year;
      }
      return date.getFullYear() === year && date.getMonth() === month;
    };

    const filteredExpenses = expenses.filter(expense => {
      const expenseDate = new Date(expense.date);
      return filterDate(expenseDate) && (expense.payer === "Bruno" || expense.shared || (expense.isDebt && expense.debtFrom === "Bruno"));
    }).map(expense => {
      let amount = expense.amount;
      if (expense.shared) {
        amount = amount / 2;
      }
      if (expense.isDebt && expense.debtFrom !== "Bruno") {
        amount = 0;
      }
      return { ...expense, amount };
    });

    const filteredIncome = income.filter(inc => {
      const incomeDate = new Date(inc.date);
      return filterDate(incomeDate);
    });

    const totalExpenses = filteredExpenses.reduce((sum, expense) => sum + expense.amount, 0);
    const totalIncome = filteredIncome.reduce((sum, inc) => sum + inc.amount, 0);
    const balance = totalIncome - totalExpenses;
    const savingsRate = totalIncome > 0 ? (balance / totalIncome) * 100 : 0;

    return { filteredExpenses, filteredIncome, totalExpenses, totalIncome, balance, savingsRate };
  }, [expenses, income]);

  const monthlyData = useMemo(() => {
    const months = Array.from({ length: 12 }, (_, i) => format(new Date(selectedYear, i, 1), 'MMM'));

    return months.map((month, index) => {
      const currentMonthExpenses = getFilteredData(selectedYear, index).totalExpenses;
      
      const incomeYear = index === 0 ? selectedYear - 1 : selectedYear;
      const incomeMonth = index === 0 ? 11 : index - 1;
      const previousMonthIncome = getFilteredData(incomeYear, incomeMonth).totalIncome;

      return {
        name: month,
        expenses: Math.round(Math.abs(currentMonthExpenses)),
        income: Math.round(previousMonthIncome)
      };
    });
  }, [selectedYear, getFilteredData]);

  const currentMonthData = useMemo(() => {
    const currentMonth = selectedMonth === 'all' ? new Date().getMonth() : selectedMonth as number;
  
    const incomeMonth = currentMonth === 0 ? 11 : currentMonth - 1;
    const incomeYear = currentMonth === 0 ? selectedYear - 1 : selectedYear;

    const expensesData = getFilteredData(selectedYear, selectedMonth);
    const incomeData = getFilteredData(incomeYear, selectedMonth === 'all' ? 'all' : incomeMonth);

    return {
      ...expensesData,
      totalIncome: incomeData.totalIncome,
      balance: incomeData.totalIncome - expensesData.totalExpenses,
      savingsRate: incomeData.totalIncome > 0 ? ((incomeData.totalIncome - expensesData.totalExpenses) / incomeData.totalIncome) * 100 : 0
    };
  }, [selectedYear, selectedMonth, getFilteredData]);

  const previousMonthData = useMemo(() => {
    const currentMonth = selectedMonth === 'all' ? new Date().getMonth() : selectedMonth as number;
    const previousMonth = currentMonth === 0 ? 11 : currentMonth - 1;
    const previousMonthYear = currentMonth === 0 ? selectedYear - 1 : selectedYear;

    return getFilteredData(previousMonthYear, previousMonth);
  }, [selectedYear, selectedMonth, getFilteredData]);

  const lastYearMonthData = useMemo(() => {
    const currentMonth = selectedMonth === 'all' ? new Date().getMonth() : selectedMonth as number;
    const lastYearMonth = currentMonth === 0 ? 11 : currentMonth - 1;

    return getFilteredData(selectedYear - 1, lastYearMonth);
  }, [selectedYear, selectedMonth, getFilteredData]);

  const averageMonthlyData = useMemo(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const isCurrentYear = selectedYear === currentDate.getFullYear();
    const monthsToAverage = isCurrentYear ? 
      (selectedMonth === 'all' ? currentMonth : selectedMonth as number) : 
      (selectedMonth === 'all' ? 12 : selectedMonth as number + 1);

    const allMonthsData = Array.from({ length: monthsToAverage }, (_, i) => getFilteredData(selectedYear, i));

    return {
      totalIncome: allMonthsData.reduce((sum, data) => sum + data.totalIncome, 0) / monthsToAverage,
      totalExpenses: allMonthsData.reduce((sum, data) => sum + data.totalExpenses, 0) / monthsToAverage,
      balance: allMonthsData.reduce((sum, data) => sum + data.balance, 0) / monthsToAverage,
      savingsRate: allMonthsData.reduce((sum, data) => sum + data.savingsRate, 0) / monthsToAverage,
    };
  }, [selectedYear, selectedMonth, getFilteredData]);

  const expenseToIncomeRatio = useMemo(() => {
    return currentMonthData.totalIncome > 0 ? (currentMonthData.totalExpenses / currentMonthData.totalIncome) * 100 : 0;
  }, [currentMonthData]);

  const handleOpenWidgetSelector = () => {
    setOpenWidgetSelector(true);
  };

  const handleCloseWidgetSelector = () => {
    setOpenWidgetSelector(false);
  };

  const handleWidgetSelectionChange = (widgets: string[]) => {
    setSelectedWidgets(widgets);
  };

  const calculateComparison = (current: number, previous: number) => {
    if (previous === 0) return current > 0 ? 100 : 0;
    return ((current - previous) / previous) * 100;
  };

  const ExpenseToIncomeRatioGauge = ({ ratio }: { ratio: number }) => {
    const theme = useTheme();
    const isPositive = ratio <= 100;
    const data = [
      { name: 'Ratio', value: Math.min(ratio, 100) },
      { name: 'Remaining', value: Math.max(100 - ratio, 0) }
    ];
    const COLORS = [
      isPositive ? theme.palette.success.main : theme.palette.error.main,
      theme.palette.grey[300]
    ];

    return (
      <ResponsiveContainer width="100%" height={200}>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="100%"
            startAngle={180}
            endAngle={0}
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            paddingAngle={5}
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <text
            x="50%"
            y="90%"
            textAnchor="middle"
            dominantBaseline="middle"
            fill={isPositive ? theme.palette.success.main : theme.palette.error.main}
            fontSize="20"
          >
            {`${ratio.toFixed(1)}%`}
          </text>
        </PieChart>
      </ResponsiveContainer>
    );
  };

  useEffect(() => {
    if (selectedWidgets.includes('categoryExpenseEvolution')) {
      console.log('Rendering CategoryExpenseChart with:', { expenses, selectedYear, selectedCategories });
    }
  }, [selectedWidgets, expenses, selectedYear, selectedCategories]);

  const handleChartTypeChange = (event: SelectChangeEvent<'area' | 'bar'>) => {
    setChartType(event.target.value as 'area' | 'bar');
  };

  const handleEditExpense = async (editedExpense: Expense, file?: File) => {
    console.log('Editing expense:', editedExpense, file);
  };

  const handleDeleteExpense = (id: string) => {
    console.log('Deleting expense:', id);
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h4" component="div">
          <FormattedMessage id="dashboard.title" />
        </Typography>
        <IconButton onClick={handleOpenWidgetSelector} color="primary">
          <DashboardIcon />
        </IconButton>
      </Box>

      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel id="year-select-label">
              <FormattedMessage id="year" />
            </InputLabel>
            <Select
              labelId="year-select-label"
              value={selectedYear}
              label={<FormattedMessage id="year" />}
              onChange={(e) => setSelectedYear(Number(e.target.value))}
            >
              {Array.from(new Set(expenses.map(e => new Date(e.date).getFullYear()))).map(year => (
                <MenuItem key={year} value={year}>{year}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel id="month-select-label">
              <FormattedMessage id="month" />
            </InputLabel>
            <Select
              labelId="month-select-label"
              value={selectedMonth}
              label={<FormattedMessage id="month" />}
              onChange={(e) => setSelectedMonth(e.target.value as number | 'all')}
            >
              <MenuItem value="all"><FormattedMessage id="allMonths" /></MenuItem>
              {Array.from({ length: 12 }, (_, i) => (
                <MenuItem key={i} value={i}>{format(new Date(2000, i, 1), 'MMMM')}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel id="payer-select-label">
              <FormattedMessage id="payer" />
            </InputLabel>
            <Select
              labelId="payer-select-label"
              value="Bruno"
              label={<FormattedMessage id="payer" />}
              disabled
            >
              <MenuItem value="Bruno">Bruno</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        {selectedWidgets.includes('totalIncome') && (
          <Grid item xs={12} sm={6} md={3}>
            <Card sx={{ height: '100%' }}>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  <FormattedMessage id="totalIncome" />
                </Typography>
                <Typography variant="h5" component="div" color="text.primary">
                  €{Math.round(currentMonthData.totalIncome)}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                  {currentMonthData.totalIncome >= previousMonthData.totalIncome ? (
                    <ArrowUpward sx={{ color: 'success.main', mr: 1 }} />
                  ) : (
                    <ArrowDownward sx={{ color: 'error.main', mr: 1 }} />
                  )}
                  <Typography variant="body2" color={currentMonthData.totalIncome >= previousMonthData.totalIncome ? 'success.main' : 'error.main'}>
                    {calculateComparison(currentMonthData.totalIncome, previousMonthData.totalIncome).toFixed(2)}%
                  </Typography>
                  <Typography variant="body2" sx={{ ml: 1 }}>vs Last Month</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                  {currentMonthData.totalIncome >= lastYearMonthData.totalIncome ? (
                    <ArrowUpward sx={{ color: 'success.main', mr: 1 }} />
                  ) : (
                    <ArrowDownward sx={{ color: 'error.main', mr: 1 }} />
                  )}
                  <Typography variant="body2" color={currentMonthData.totalIncome >= lastYearMonthData.totalIncome ? 'success.main' : 'error.main'}>
                    {calculateComparison(currentMonthData.totalIncome, lastYearMonthData.totalIncome).toFixed(2)}%
                  </Typography>
                  <Typography variant="body2" sx={{ ml: 1 }}>vs Last Year</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                  <Icon component={BarChart} sx={{ color: 'info.main', mr: 1 }} />
                  <Typography variant="body2">
                    YTD Average: €{Math.round(averageMonthlyData.totalIncome)}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        )}

        {selectedWidgets.includes('totalExpenses') && (
          <Grid item xs={12} sm={6} md={3}>
            <Card sx={{ height: '100%' }}>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  <FormattedMessage id="totalExpenses" />
                </Typography>
                <Typography variant="h5" component="div" color="text.primary">
                  €{Math.round(currentMonthData.totalExpenses)}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                  {currentMonthData.totalExpenses >= previousMonthData.totalExpenses ? (
                    <ArrowUpward sx={{ color: 'success.main', mr: 1 }} />
                  ) : (
                    <ArrowDownward sx={{ color: 'error.main', mr: 1 }} />
                  )}
                  <Typography variant="body2" color={currentMonthData.totalExpenses >= previousMonthData.totalExpenses ? 'success.main' : 'error.main'}>
                    {calculateComparison(currentMonthData.totalExpenses, previousMonthData.totalExpenses).toFixed(2)}%
                  </Typography>
                  <Typography variant="body2" sx={{ ml: 1 }}>vs Last Month</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                  {currentMonthData.totalExpenses >= lastYearMonthData.totalExpenses ? (
                    <ArrowUpward sx={{ color: 'success.main', mr: 1 }} />
                  ) : (
                    <ArrowDownward sx={{ color: 'error.main', mr: 1 }} />
                  )}
                  <Typography variant="body2" color={currentMonthData.totalExpenses >= lastYearMonthData.totalExpenses ? 'success.main' : 'error.main'}>
                    {calculateComparison(currentMonthData.totalExpenses, lastYearMonthData.totalExpenses).toFixed(2)}%
                  </Typography>
                  <Typography variant="body2" sx={{ ml: 1 }}>vs Last Year</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                  <Icon component={BarChart} sx={{ color: 'info.main', mr: 1 }} />
                  <Typography variant="body2">
                    YTD Average: €{Math.round(averageMonthlyData.totalExpenses)}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        )}

        {selectedWidgets.includes('balance') && (
          <Grid item xs={12} sm={6} md={3}>
            <Card sx={{ height: '100%' }}>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  <FormattedMessage id="balance" />
                </Typography>
                <Typography variant="h5" component="div" color={currentMonthData.balance >= 0 ? 'success.main' : 'error.main'}>
                  €{Math.round(currentMonthData.balance)}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                  {currentMonthData.balance >= previousMonthData.balance ? (
                    <ArrowUpward sx={{ color: 'success.main', mr: 1 }} />
                  ) : (
                    <ArrowDownward sx={{ color: 'error.main', mr: 1 }} />
                  )}
                  <Typography variant="body2" color={currentMonthData.balance >= previousMonthData.balance ? 'success.main' : 'error.main'}>
                    {calculateComparison(currentMonthData.balance, previousMonthData.balance).toFixed(2)}%
                  </Typography>
                  <Typography variant="body2" sx={{ ml: 1 }}>vs Last Month</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                  {currentMonthData.balance >= lastYearMonthData.balance ? (
                    <ArrowUpward sx={{ color: 'success.main', mr: 1 }} />
                  ) : (
                    <ArrowDownward sx={{ color: 'error.main', mr: 1 }} />
                  )}
                  <Typography variant="body2" color={currentMonthData.balance >= lastYearMonthData.balance ? 'success.main' : 'error.main'}>
                    {calculateComparison(currentMonthData.balance, lastYearMonthData.balance).toFixed(2)}%
                  </Typography>
                  <Typography variant="body2" sx={{ ml: 1 }}>vs Last Year</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                  <Icon component={BarChart} sx={{ color: 'info.main', mr: 1 }} />
                  <Typography variant="body2">
                    YTD Average: €{Math.round(averageMonthlyData.balance)}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        )}

        {selectedWidgets.includes('savingsRate') && (
          <Grid item xs={12} sm={6} md={3}>
            <Card sx={{ height: '100%' }}>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  <FormattedMessage id="savingsRate" />
                </Typography>
                <Typography variant="h5" component="div" color={currentMonthData.savingsRate >= 0 ? 'success.main' : 'error.main'}>
                  {Math.round(currentMonthData.savingsRate)}%
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                  {currentMonthData.savingsRate >= previousMonthData.savingsRate ? (
                    <ArrowUpward sx={{ color: 'success.main', mr: 1 }} />
                  ) : (
                    <ArrowDownward sx={{ color: 'error.main', mr: 1 }} />
                  )}
                  <Typography variant="body2" color={currentMonthData.savingsRate >= previousMonthData.savingsRate ? 'success.main' : 'error.main'}>
                    {(currentMonthData.savingsRate - previousMonthData.savingsRate).toFixed(2)}%
                  </Typography>
                  <Typography variant="body2" sx={{ ml: 1 }}>vs Last Month</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                  {currentMonthData.savingsRate >= lastYearMonthData.savingsRate ? (
                    <ArrowUpward sx={{ color: 'success.main', mr: 1 }} />
                  ) : (
                    <ArrowDownward sx={{ color: 'error.main', mr: 1 }} />
                  )}
                  <Typography variant="body2" color={currentMonthData.savingsRate >= lastYearMonthData.savingsRate ? 'success.main' : 'error.main'}>
                    {(currentMonthData.savingsRate - lastYearMonthData.savingsRate).toFixed(2)}%
                  </Typography>
                  <Typography variant="body2" sx={{ ml: 1 }}>vs Last Year</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                  <Icon component={BarChart} sx={{ color: 'info.main', mr: 1 }} />
                  <Typography variant="body2">
                    YTD Average: {averageMonthlyData.savingsRate.toFixed(2)}%
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        )}

        {selectedWidgets.includes('incomeVsExpenses') && (
          <Grid item xs={12} md={6}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="h6" gutterBottom>
                    <FormattedMessage id="incomeVsExpenses" />
                  </Typography>
                  <FormControl variant="outlined" size="small">
                    <InputLabel id="chart-type-select-label">
                      <FormattedMessage id="chartType" />
                    </InputLabel>
                    <Select
                      labelId="chart-type-select-label"
                      value={chartType}
                      onChange={handleChartTypeChange}
                      label={<FormattedMessage id="chartType" />}
                    >
                      <MenuItem value="area"><FormattedMessage id="areaChart" /></MenuItem>
                      <MenuItem value="bar"><FormattedMessage id="barChart" /></MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box sx={{ flexGrow: 1, minHeight: 300, display: 'flex', flexDirection: 'column' }}>
                  <ResponsiveContainer width="100%" height="100%">
                    {chartType === 'area' ? (
                      <AreaChart data={monthlyData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                        <defs>
                          <linearGradient id="incomeGradient" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={theme.palette.success.main} stopOpacity={0.8} />
                            <stop offset="95%" stopColor={theme.palette.success.main} stopOpacity={0} />
                          </linearGradient>
                          <linearGradient id="expensesGradient" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={theme.palette.error.main} stopOpacity={0.8} />
                            <stop offset="95%" stopColor={theme.palette.error.main} stopOpacity={0} />
                          </linearGradient>
                        </defs>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis tickFormatter={(value) => `€${value}`} />
                        <Tooltip 
                          content={({ active, payload, label }) => {
                            if (active && payload && payload.length) {
                              const income = payload.find(p => p.dataKey === 'income')?.value as number;
                              const expenses = payload.find(p => p.dataKey === 'expenses')?.value as number;
                              const savingsRate = ((income - expenses) / income) * 100;
                              return (
                                <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
                                  <p>{label}</p>
                                  <p style={{ color: theme.palette.success.main }}>Income: €{income.toFixed(2)}</p>
                                  <p style={{ color: theme.palette.error.main }}>Expenses: €{expenses.toFixed(2)}</p>
                                  <p>Savings Rate: {savingsRate.toFixed(2)}%</p>
                                </div>
                              );
                            }
                            return null;
                          }}
                        />
                        <Legend />
                        <Area type="monotone" dataKey="income" name={intl.formatMessage({ id: 'income' })} stroke={theme.palette.success.main} fillOpacity={1} fill="url(#incomeGradient)" />
                        <Area type="monotone" dataKey="expenses" name={intl.formatMessage({ id: 'expenses' })} stroke={theme.palette.error.main} fillOpacity={1} fill="url(#expensesGradient)" />
                        <Line
                          type="monotone"
                          dataKey="income"
                          stroke={theme.palette.success.dark}
                          strokeWidth={2}
                          dot={false}
                          name="Avg YTD Income"
                          strokeDasharray="5 5"
                        />
                        <Line
                          type="monotone"
                          dataKey="expenses"
                          stroke={theme.palette.error.dark}
                          strokeWidth={2}
                          dot={false}
                          name="Avg YTD Expense"
                          strokeDasharray="5 5"
                        />
                      </AreaChart>
                    ) : (
                      <RechartsBarChart data={monthlyData} margin={{ top: 20, right: 30, left: 20, bottom: 30 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis 
                          dataKey="name" 
                          interval={0}
                          angle={-45}
                          textAnchor="end"
                          height={70}
                        />
                        <YAxis tickFormatter={(value) => `€${value}`} />
                        <Tooltip 
                          content={({ active, payload, label }) => {
                            if (active && payload && payload.length) {
                              const income = payload.find(p => p.dataKey === 'income')?.value as number;
                              const expenses = payload.find(p => p.dataKey === 'expenses')?.value as number;
                              const savingsRate = ((income - expenses) / income) * 100;
                              return (
                                <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
                                  <p>{label}</p>
                                  <p style={{ color: theme.palette.success.main }}>Income: €{income.toFixed(2)}</p>
                                  <p style={{ color: theme.palette.error.main }}>Expenses: €{expenses.toFixed(2)}</p>
                                  <p>Savings Rate: {savingsRate.toFixed(2)}%</p>
                                </div>
                              );
                            }
                            return null;
                          }}
                        />
                        <Legend />
                        <Bar dataKey="income" name={intl.formatMessage({ id: 'income' })} fill={theme.palette.success.main} barSize={20} />
                        <Bar dataKey="expenses" name={intl.formatMessage({ id: 'expenses' })} fill={theme.palette.error.main} barSize={20} />
                        <Line
                          type="monotone"
                          dataKey="income"
                          stroke={theme.palette.success.dark}
                          strokeWidth={2}
                          dot={false}
                          name="Avg YTD Income"
                          strokeDasharray="5 5"
                        />
                        <Line
                          type="monotone"
                          dataKey="expenses"
                          stroke={theme.palette.error.dark}
                          strokeWidth={2}
                          dot={false}
                          name="Avg YTD Expense"
                          strokeDasharray="5 5"
                        />
                      </RechartsBarChart>
                    )}
                  </ResponsiveContainer>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle2" color="textSecondary">
                        <FormattedMessage id="averageMonthlyIncome" />
                      </Typography>
                      <Typography variant="body1">
                        €{averageMonthlyData.totalIncome.toFixed(2)}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle2" color="textSecondary">
                        <FormattedMessage id="averageMonthlyExpenses" />
                      </Typography>
                      <Typography variant="body1">
                        €{averageMonthlyData.totalExpenses.toFixed(2)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2" color="textSecondary">
                        <FormattedMessage id="incomeVsExpensesTrend" />
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {averageMonthlyData.totalIncome > averageMonthlyData.totalExpenses ? (
                          <>
                            <TrendingUp sx={{ color: theme.palette.success.main, mr: 1 }} />
                            <Typography variant="body1" color="success.main">
                              <FormattedMessage id="positiveIncomeExpenseRatio" />
                            </Typography>
                          </>
                        ) : (
                          <>
                            <TrendingDown sx={{ color: theme.palette.error.main, mr: 1 }} />
                            <Typography variant="body1" color="error.main">
                              <FormattedMessage id="negativeIncomeExpenseRatio" />
                            </Typography>
                          </>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        )}

        {selectedWidgets.includes('expenseCategories') && (
          <Grid item xs={12} md={6}>
            <Card sx={{ height: 'auto', minHeight: '400px', display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h6" gutterBottom>
                  <FormattedMessage id="expenseCategories" />
                </Typography>
                <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
                  {currentMonthData.filteredExpenses
                    .reduce((acc, expense) => {
                      const existingCategory = acc.find(item => item.category === expense.category);
                      if (existingCategory) {
                        existingCategory.amount += expense.amount;
                      } else {
                        acc.push({ category: expense.category, amount: expense.amount });
                      }
                      return acc;
                    }, [] as { category: string; amount: number }[])
                    .sort((a, b) => b.amount - a.amount)
                    .map((categoryData, index) => {
                      const previousMonthAmount = previousMonthData.filteredExpenses
                        .filter(e => e.category === categoryData.category)
                        .reduce((sum, e) => sum + e.amount, 0);
                      const lastYearAmount = lastYearMonthData.filteredExpenses
                        .filter(e => e.category === categoryData.category)
                        .reduce((sum, e) => sum + e.amount, 0);

                      const vsLastMonth = calculateComparison(categoryData.amount, previousMonthAmount);
                      const vsLastYear = calculateComparison(categoryData.amount, lastYearAmount);

                      const tooltipContent = (
                        <div>
                          <p>Current: €{Math.abs(categoryData.amount).toFixed(2)}</p>
                          <p>Last Month: €{Math.abs(previousMonthAmount).toFixed(2)}</p>
                          <p>vs Last Month: {vsLastMonth.toFixed(2)}% (€{(categoryData.amount - previousMonthAmount).toFixed(2)})</p>
                          <p>Last Year: €{Math.abs(lastYearAmount).toFixed(2)}</p>
                          <p>vs Last Year: {vsLastYear.toFixed(2)}% (€{(categoryData.amount - lastYearAmount).toFixed(2)})</p>
                        </div>
                      );

                      return (
                        <MuiTooltip
                          key={index}
                          title={tooltipContent}
                          arrow
                          placement="right"
                        >
                          <Box sx={{ mb: 2 }}>
                            <Typography variant="body2">
                              {categoryData.category}: €{Math.abs(Math.round(categoryData.amount))} ({Math.round((Math.abs(categoryData.amount) / Math.abs(currentMonthData.totalExpenses)) * 100)}%)
                            </Typography>
                            <LinearProgress 
                              variant="determinate" 
                              value={Math.round((Math.abs(categoryData.amount) / Math.abs(currentMonthData.totalExpenses)) * 100)} 
                              sx={{ 
                                height: 10, 
                                borderRadius: 5,
                                backgroundColor: theme.palette.grey[300],
                                '& .MuiLinearProgress-bar': {
                                  borderRadius: 5,
                                  backgroundColor: CHART_COLORS[index % CHART_COLORS.length],
                                },
                              }}
                            />
                          </Box>
                        </MuiTooltip>
                      );
                    })}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        )}

        {selectedWidgets.includes('expenseToIncomeRatio') && (
          <Grid item xs={12} md={6}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Typography variant="h6" gutterBottom>
                  <FormattedMessage id="expenseToIncomeRatio" />
                </Typography>
                <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <ExpenseToIncomeRatioGauge ratio={expenseToIncomeRatio} />
                </Box>
                <Typography variant="body2" sx={{ mt: 2 }}>
                  <FormattedMessage id="expenseToIncomeRatioExplanation" />
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        )}

        {selectedWidgets.includes('categoryExpenseEvolution') && (
          <Grid item xs={12}>
            <CategoryExpenseChart 
              expenses={expenses} 
              selectedYear={selectedYear}
            />
          </Grid>
        )}

        {selectedWidgets.includes('filteredExpenses') && (
          <Grid item xs={12}>
            <FilteredExpenses
              expenses={expenses}
              filter={{
                year: selectedYear,
                month: selectedMonth,
                payer: selectedPayer || ''
              }}
              payers={payers}
              categories={Array.from(new Set(expenses.map(e => e.category)))}
              onEditExpense={handleEditExpense}
              onDeleteExpense={handleDeleteExpense}
              payerColors={payerColors}
            />
          </Grid>
        )}
      </Grid>

      <WidgetSelector
        open={openWidgetSelector}
        onClose={handleCloseWidgetSelector}
        selectedWidgets={selectedWidgets}
        onSelectionChange={handleWidgetSelectionChange}
        availableWidgets={[
          'totalIncome', 'totalExpenses', 'balance', 'savingsRate',
          'incomeVsExpenses', 'expenseCategories', 'expenseToIncomeRatio',
          'categoryExpenseEvolution', 'filteredExpenses'
        ]}
      />
    </Box>
  );
};

export default Dashboard;