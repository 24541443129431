import React, { useState, useMemo } from 'react';
import { Card, CardContent, Typography, Button, Grid, Chip, Box } from '@mui/material';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, TooltipProps } from 'recharts';
import { Expense } from '../types/Expense';
import { FormattedMessage } from 'react-intl';

interface CategoryExpenseChartProps {
  expenses: Expense[];
  selectedYear: number;
}

const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const COLORS = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'];

const CategoryExpenseChart: React.FC<CategoryExpenseChartProps> = ({ expenses, selectedYear }) => {
  const categories = useMemo(() => {
    const uniqueCategories = Array.from(new Set(expenses.map(e => e.category)));
    return uniqueCategories.sort((a, b) => a.localeCompare(b));
  }, [expenses]);

  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const chartData = useMemo(() => {
    const monthlyData = MONTHS.map(month => ({ name: month } as { [key: string]: string | number }));
    const ytdTotals: { [key: string]: number } = {};
    const ytdCounts: { [key: string]: number } = {};

    categories.forEach((category) => {
      ytdTotals[category] = 0;
      ytdCounts[category] = 0;

      MONTHS.forEach((_, monthIndex) => {
        const monthlyExpenses = expenses.filter(e => 
          new Date(e.date).getFullYear() === selectedYear &&
          new Date(e.date).getMonth() === monthIndex &&
          e.category === category
        );
        const totalAmount = monthlyExpenses.reduce((sum, e) => sum + e.amount, 0);
        monthlyData[monthIndex][category] = totalAmount;

        // Calculate YTD totals and counts
        if (totalAmount > 0) {
          ytdTotals[category] += totalAmount;
          ytdCounts[category]++;
        }
      });

      // Calculate and add YTD average to each month's data
      monthlyData.forEach(monthData => {
        monthData[`${category}Avg`] = ytdCounts[category] > 0 
          ? ytdTotals[category] / ytdCounts[category] 
          : 0;
      });
    });

    return monthlyData;
  }, [expenses, selectedYear, categories]);

  const handleCategoryToggle = (category: string) => {
    setSelectedCategories(prev => 
      prev.includes(category) 
        ? prev.filter(c => c !== category)
        : [...prev, category]
    );
  };

  const handleSelectAll = () => {
    setSelectedCategories(categories);
  };

  const handleDeselectAll = () => {
    setSelectedCategories([]);
  };

  const CustomTooltip = ({ active, payload, label }: TooltipProps<number, string>) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
          <p className="label">{`${label}`}</p>
          {payload.map((entry, index) => (
            <p key={index} style={{ color: entry.color }}>
              {`${entry.name || ''}: €${entry.value?.toFixed(2)}`}
              {entry.name && entry.name.endsWith('Avg') ? ' (YTD Avg)' : ''}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          <FormattedMessage id="categoryExpenseEvolution" />
        </Typography>
        <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box>
            <Button onClick={handleSelectAll} variant="outlined" size="small" sx={{ mr: 1 }}>
              <FormattedMessage id="selectAll" />
            </Button>
            <Button onClick={handleDeselectAll} variant="outlined" size="small">
              <FormattedMessage id="deselectAll" />
            </Button>
          </Box>
          <Typography variant="body2">
            <FormattedMessage id="selectedCategories" />: {selectedCategories.length}/{categories.length}
          </Typography>
        </Box>
        <Box sx={{ mb: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {categories.map((category, index) => (
            <Chip
              key={category}
              label={category}
              onClick={() => handleCategoryToggle(category)}
              color={selectedCategories.includes(category) ? "primary" : "default"}
            />
          ))}
        </Box>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis 
              tickFormatter={(value) => `€${value}`}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            {selectedCategories.map((category, index) => (
              <React.Fragment key={category}>
                <Line
                  type="monotone"
                  dataKey={category}
                  name={`€ ${category}`}
                  stroke={COLORS[index % COLORS.length]}
                  activeDot={{ r: 8 }}
                />
                <Line
                  type="monotone"
                  dataKey={`${category}Avg`}
                  name={`€ ${category} Avg`}
                  stroke={COLORS[index % COLORS.length]}
                  strokeDasharray="5 5"
                  dot={false}
                />
              </React.Fragment>
            ))}
          </LineChart>
        </ResponsiveContainer>
        {selectedCategories.length === 0 && (
          <Typography align="center" sx={{ mt: 2 }}>
            <FormattedMessage id="selectCategoriesForChart" />
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default CategoryExpenseChart;