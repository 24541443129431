import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { TextField, Select, MenuItem, FormControlLabel, Switch, Button, Box, Typography, InputLabel, FormControl, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemText, IconButton, Tooltip, Input, Checkbox, Snackbar, Autocomplete, CircularProgress } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { SelectChangeEvent } from '@mui/material/Select';
import { Add, Save, Cancel, Edit, Delete, Settings, Attachment, Share, AccountBalance } from '@mui/icons-material';
import { motion } from 'framer-motion';
import { Expense } from '../types/Expense';
import { uploadFile } from '../utils/fileUpload';
import { addExpense } from '../firebase/firestoreOperations';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useIntl, FormattedMessage } from 'react-intl';
import { ChromePicker } from 'react-color';
import CustomChip from './CustomChip';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useNavigate } from 'react-router-dom';

interface ExpenseFormProps {
  onAddExpense: (expense: Omit<Expense, 'id' | 'userId'>, file?: File) => Promise<void>;
  categories: string[];
  payers: string[];
  onAddCategory: (category: string) => void;
  onEditCategory: (oldCategory: string, newCategory: string) => void;
  onDeleteCategory: (category: string) => void;
  onAddPayer: (payer: string) => void;
  onEditPayer: (oldPayer: string, newPayer: string) => void;
  onDeletePayer: (payer: string) => void;
  onEditPayerColor: (payer: string, color: string) => void;
  payerColors: { [key: string]: string };
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ExpenseForm: React.FC<ExpenseFormProps> = ({ onAddExpense, categories, payers, onAddCategory, onEditCategory, onDeleteCategory, onAddPayer, onEditPayer, onDeletePayer, onEditPayerColor, payerColors }) => {
  const [expense, setExpense] = useState({
    description: '',
    date: '',
    payer: '',
    category: '',
    shared: false,
    sharedWith: '',
    amount: '',
  });
  const [newCategory, setNewCategory] = useState('');
  const [isAddingCategory, setIsAddingCategory] = useState(false);
  const [editingCategory, setEditingCategory] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [sortedCategories, setSortedCategories] = useState<string[]>([]);
  const [newPayer, setNewPayer] = useState('');
  const [isAddingPayer, setIsAddingPayer] = useState(false);
  const [editingPayer, setEditingPayer] = useState<string | null>(null);
  const intl = useIntl();

  const [openPayerDialog, setOpenPayerDialog] = useState(false);
  const [editingPayerName, setEditingPayerName] = useState('');
  const [newPayerName, setNewPayerName] = useState('');
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [selectedPayer, setSelectedPayer] = useState('');
  const [selectedColor, setSelectedColor] = useState('#000000');

  const [isDebt, setIsDebt] = useState(false);
  const [debtFrom, setDebtFrom] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [descriptionSuggestions, setDescriptionSuggestions] = useState<string[]>([]);

  const longestPayerName = useMemo(() => {
    return payers.reduce((longest, current) => 
      current.length > longest.length ? current : longest
    , '');
  }, [payers]);

  useEffect(() => {
    setSortedCategories([...categories].sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' })));
  }, [categories]);

  const sortedPayers = useMemo(() => {
    const preferredOrder = ['Bruno', 'Simone', 'Bárbara'];
    return [
      ...preferredOrder.filter(payer => payers.includes(payer)),
      ...payers.filter(payer => !preferredOrder.includes(payer)).sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }))
    ];
  }, [payers]);

  useEffect(() => {
    // Load description suggestions from localStorage
    const savedSuggestions = localStorage.getItem('expenseDescriptionSuggestions');
    if (savedSuggestions) {
      setDescriptionSuggestions(JSON.parse(savedSuggestions));
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    if (name === 'category' && value === 'new') {
      setIsAddingCategory(true);
    } else if (name === 'payer' && value === 'new') {
      setIsAddingPayer(true);
    } else {
      setExpense(prev => ({
        ...prev,
        [name]: name === 'shared' ? (e.target as HTMLInputElement).checked : value,
      }));
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Permite números, um separador decimal (vírgula ou ponto) e até duas casas decimais
    // Substitui a vírgula por ponto para garantir consistência
    const sanitizedValue = value.replace(',', '.');
    if (/^\d*\.?\d{0,2}$/.test(sanitizedValue)) {
      setExpense(prev => ({ ...prev, amount: sanitizedValue }));
    }
  };

  const formatAmount = (value: string) => {
    // Substitui vírgulas por pontos para cálculos internos
    return value.replace(',', '.');
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();

    if (!expense.description || !expense.date || !expense.payer || !expense.category || parseFloat(formatAmount(expense.amount)) <= 0) {
      alert(intl.formatMessage({ id: 'fillAllFields' }));
      return;
    }

    setIsSubmitting(true);

    try {
      const newExpense: Omit<Expense, 'id' | 'userId'> = {
        description: expense.description,
        date: expense.date,
        payer: isDebt ? debtFrom : expense.payer,
        category: expense.category,
        shared: expense.shared,
        sharedWith: expense.shared ? expense.sharedWith || '' : undefined,
        amount: parseFloat(formatAmount(expense.amount)),
        debtFrom: isDebt ? expense.payer : null,
        isDebt: isDebt,
      };

      await onAddExpense(newExpense, selectedFile || undefined);
      
      console.log('Nova despesa adicionada:', newExpense); // Adicione este log
      
      // Resetar o formulário
      setExpense({
        description: '',
        date: new Date().toISOString().split('T')[0],
        payer: '',
        category: '',
        shared: false,
        sharedWith: '',
        amount: '',
      });
      setSelectedFile(null);
      setIsDebt(false);
      setDebtFrom('');
      setOpenSnackbar(true);

      // Refresh the page by navigating to the current location
      navigate(0);
    } catch (error) {
      console.error('Error adding expense:', error);
      alert(intl.formatMessage({ id: 'errorAddingExpense' }));
    } finally {
      setIsSubmitting(false);
    }
  }, [expense, isDebt, debtFrom, selectedFile, onAddExpense, intl, formatAmount, navigate]);

  const handleEditCategory = (category: string) => {
    setEditingCategory(category);
    setNewCategory(category);
  };

  const handleSaveEditedCategory = () => {
    const trimmedCategory = newCategory.trim();
    if (editingCategory && trimmedCategory !== '' && trimmedCategory !== editingCategory) {
      if (!categories.includes(trimmedCategory)) {
        onEditCategory(editingCategory, trimmedCategory);
        setExpense(prev => ({
          ...prev,
          category: prev.category === editingCategory ? trimmedCategory : prev.category
        }));
        setEditingCategory(null);
        setNewCategory('');
      } else {
        alert(`${intl.formatMessage({ id: 'categoryExists' })} "${trimmedCategory}".`);
      }
    } else {
      setEditingCategory(null);
      setNewCategory('');
    }
  };

  const handleAddCategory = () => {
    const trimmedCategory = newCategory.trim();
    if (trimmedCategory !== '') {
      if (!categories.includes(trimmedCategory)) {
        onAddCategory(trimmedCategory);
        setIsAddingCategory(false);
        setExpense({...expense, category: trimmedCategory});
        setNewCategory('');
      } else {
        alert(`${intl.formatMessage({ id: 'categoryExists' })} "${trimmedCategory}".`);
      }
    }
  };

  const handleAddPayer = () => {
    const trimmedPayer = newPayer.trim();
    if (trimmedPayer !== '') {
      if (!payers.includes(trimmedPayer)) {
        onAddPayer(trimmedPayer);
        setIsAddingPayer(false);
        setExpense({...expense, payer: trimmedPayer});
        setNewPayer('');
      } else {
        alert(`${intl.formatMessage({ id: 'payerExists' })} "${trimmedPayer}".`);
      }
    }
  };

  const handleEditPayer = (payer: string) => {
    setEditingPayer(payer);
    setNewPayer(payer);
  };

  const handleSaveEditedPayer = () => {
    if (editingPayer && newPayer.trim() !== '' && newPayer !== editingPayer) {
      onEditPayer(editingPayer, newPayer.trim());
      setExpense(prev => ({
        ...prev,
        payer: prev.payer === editingPayer ? newPayer.trim() : prev.payer,
        sharedWith: prev.sharedWith === editingPayer ? newPayer.trim() : prev.sharedWith
      }));
    }
    setEditingPayer(null);
    setNewPayer('');
  };

  const handleDeleteCategory = (category: string) => {
    if (window.confirm(`${intl.formatMessage({ id: 'confirmDelete' })} "${category}"?`)) {
      onDeleteCategory(category);
      if (expense.category === category) {
        setExpense(prev => ({ ...prev, category: '' }));
      }
    }
  };

  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const [editingCategoryName, setEditingCategoryName] = useState('');
  const [newCategoryName, setNewCategoryName] = useState('');

  const handleOpenCategoryDialog = () => {
    setOpenCategoryDialog(true);
  };

  const handleCloseCategoryDialog = () => {
    setOpenCategoryDialog(false);
    setEditingCategoryName('');
    setNewCategoryName('');
  };

  const handleEditCategoryStart = (category: string) => {
    setEditingCategoryName(category);
    setNewCategoryName(category);
  };

  const handleEditCategoryConfirm = () => {
    if (newCategoryName.trim() !== '' && newCategoryName !== editingCategoryName) {
      onEditCategory(editingCategoryName, newCategoryName.trim());
      setEditingCategoryName('');
      setNewCategoryName('');
    }
  };

  const handleDeleteCategoryConfirm = (category: string) => {
    if (window.confirm(`${intl.formatMessage({ id: 'confirmDelete' })} "${category}"?`)) {
      onDeleteCategory(category);
    }
  };

  const handleDateChange = (newValue: Date | null) => {
    if (newValue instanceof Date) {
      // Ensure the date is not affected by timezone
      const localDate = new Date(newValue.getTime() - newValue.getTimezoneOffset() * 60000);
      setExpense(prev => ({ ...prev, date: localDate.toISOString().split('T')[0] }));
    } else if (newValue === null) {
      setExpense(prev => ({ ...prev, date: '' }));
    }
  };

  const handleOpenPayerDialog = () => {
    setOpenPayerDialog(true);
  };

  const handleClosePayerDialog = () => {
    setOpenPayerDialog(false);
    setEditingPayerName('');
    setNewPayerName('');
  };

  const handleEditPayerStart = (payer: string) => {
    setEditingPayerName(payer);
    setNewPayerName(payer);
  };

  const handleEditPayerConfirm = () => {
    if (newPayerName.trim() !== '' && newPayerName !== editingPayerName) {
      onEditPayer(editingPayerName, newPayerName.trim());
      setEditingPayerName('');
      setNewPayerName('');
    }
  };

  const handleDeletePayerConfirm = (payer: string) => {
    if (window.confirm(`${intl.formatMessage({ id: 'confirmDelete' })} "${payer}"?`)) {
      onDeletePayer(payer);
    }
  };

  const handleOpenColorPicker = (payer: string) => {
    setSelectedPayer(payer);
    setSelectedColor(payerColors[payer] || '#000000');
    setOpenColorPicker(true);
  };

  const handleCloseColorPicker = () => {
    setOpenColorPicker(false);
  };

  const handleColorChange = (color: any) => {
    setSelectedColor(color.hex);
  };

  const handleColorChangeComplete = () => {
    onEditPayerColor(selectedPayer, selectedColor);
    setOpenColorPicker(false);
  };

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option: string) => option,
  });

  const handleDescriptionChange = (event: React.SyntheticEvent, newValue: string | null) => {
    if (typeof newValue === 'string') {
      setExpense(prev => ({ ...prev, description: newValue }));
      
      // Update suggestions
      if (!descriptionSuggestions.includes(newValue)) {
        const newSuggestions = [...descriptionSuggestions, newValue].slice(-20); // Keep last 20 suggestions
        setDescriptionSuggestions(newSuggestions);
        localStorage.setItem('expenseDescriptionSuggestions', JSON.stringify(newSuggestions));
      }
    }
  };

  const handleSharedWithChange = (event: SelectChangeEvent<string>) => {
    setExpense(prev => ({ ...prev, sharedWith: event.target.value }));
  };

  const handleDebtFromChange = (event: SelectChangeEvent<string>) => {
    setDebtFrom(event.target.value);
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      // Adicione aqui qualquer lógica de carregamento necessária
      setIsLoading(false);
    };

    loadData();
  }, []);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {error && <Typography color="error">{error}</Typography>}
          
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FormControl fullWidth>
              <InputLabel id="category-label"><FormattedMessage id="category" /></InputLabel>
              <Select
                labelId="category-label"
                label={intl.formatMessage({ id: 'category' })}
                name="category"
                value={expense.category}
                onChange={handleChange}
                required
              >
                <MenuItem value=""><FormattedMessage id="selectCategory" /></MenuItem>
                {sortedCategories.map((category) => (
                  <MenuItem key={category} value={category}>{category}</MenuItem>
                ))}
                <MenuItem value="new">+ <FormattedMessage id="addNewCategory" /></MenuItem>
              </Select>
            </FormControl>
            <Tooltip title={intl.formatMessage({ id: 'manageCategories' })}>
              <IconButton
                size="small"
                onClick={handleOpenCategoryDialog}
                sx={{ ml: 1 }}
              >
                <Settings fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>

          {isAddingCategory && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <TextField
                label={intl.formatMessage({ id: 'newCategory' })}
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
                fullWidth
              />
              <Button variant="contained" color="primary" startIcon={<Add />} onClick={handleAddCategory}>
                <FormattedMessage id="add" />
              </Button>
            </Box>
          )}
          
          <Autocomplete
            freeSolo
            options={descriptionSuggestions}
            value={expense.description}
            onChange={handleDescriptionChange}
            onInputChange={(event, newInputValue) => {
              setExpense(prev => ({ ...prev, description: newInputValue }));
            }}
            filterOptions={filterOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                label={intl.formatMessage({ id: 'description' })}
                name="description"
                required
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable browser autocomplete
                }}
              />
            )}
          />
          
          <DatePicker
            label={intl.formatMessage({ id: 'date' })}
            value={expense.date ? new Date(expense.date) : null}
            onChange={(newValue) => handleDateChange(newValue as Date | null)}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                required
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {params.InputProps?.endAdornment}
                    </React.Fragment>
                  ),
                }}
                inputProps={{
                  ...params.inputProps,
                  readOnly: true,
                }}
                sx={{
                  '& .MuiInputBase-root': {
                    cursor: 'pointer',
                  },
                  '& .MuiInputBase-input': {
                    cursor: 'pointer',
                  },
                }}
                onClick={(event) => {
                  if (params.inputProps?.onClick) {
                    (params.inputProps.onClick as React.MouseEventHandler<HTMLDivElement>)(event);
                  }
                }}
              />
            )}
          />
          
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FormControl fullWidth>
              <InputLabel id="payer-label" shrink={!!expense.payer}>{intl.formatMessage({ id: 'payer' })}</InputLabel>
              <Select
                labelId="payer-label"
                label={intl.formatMessage({ id: 'payer' })}
                name="payer"
                value={expense.payer}
                onChange={handleChange}
                required
                notched
              >
                <MenuItem value=""><FormattedMessage id="selectPayer" /></MenuItem>
                {sortedPayers.map((payer) => (
                  <MenuItem key={payer} value={payer}>{payer}</MenuItem>
                ))}
                <MenuItem value="new">+ <FormattedMessage id="addNewPayer" /></MenuItem>
              </Select>
            </FormControl>
            <Tooltip title={intl.formatMessage({ id: 'managePayers' })}>
              <IconButton
                size="small"
                onClick={handleOpenPayerDialog}
                sx={{ ml: 1 }}
              >
                <Settings fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>

          {isAddingPayer && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <TextField
                label={intl.formatMessage({ id: 'newPayer' })}
                value={newPayer}
                onChange={(e) => setNewPayer(e.target.value)}
                fullWidth
              />
              <Button variant="contained" color="primary" startIcon={<Add />} onClick={handleAddPayer}>
                <FormattedMessage id="add" />
              </Button>
            </Box>
          )}
          
          <TextField
            type="text"
            label={`${intl.formatMessage({ id: 'amount' })} (€)`}
            name="amount"
            value={expense.amount}
            onChange={handleAmountChange}
            required
            fullWidth
            InputProps={{ 
              style: { textAlign: 'right' },
            }}
            inputProps={{
              inputMode: 'decimal',
              pattern: '[0-9]*[.,]?[0-9]*'
            }}
            onKeyPress={(e) => {
              // Permite apenas números, ponto e vírgula
              const pattern = /[0-9.,]/;
              if (!pattern.test(e.key)) {
                e.preventDefault();
              }
            }}
          />
          
          <Box sx={{ display: 'flex', gap: 2 }}>
            <FormControl fullWidth>
              <FormControlLabel
                control={
                  <Switch
                    checked={expense.shared}
                    onChange={handleChange}
                    name="shared"
                  />
                }
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Share sx={{ mr: 1 }} />
                    <FormattedMessage id="shared" />
                  </Box>
                }
              />
              {expense.shared && (
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="shared-with-label"><FormattedMessage id="sharedWith" /></InputLabel>
                  <Select
                    labelId="shared-with-label"
                    label={intl.formatMessage({ id: 'sharedWith' })}
                    name="sharedWith"
                    value={expense.sharedWith}
                    onChange={handleSharedWithChange}
                    required
                  >
                    <MenuItem value=""><FormattedMessage id="selectPerson" /></MenuItem>
                    {payers.filter(p => p !== expense.payer).map(payer => (
                      <MenuItem key={payer} value={payer}>{payer}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </FormControl>

            <FormControl fullWidth>
              <FormControlLabel
                control={
                  <Switch
                    checked={isDebt}
                    onChange={(e) => setIsDebt(e.target.checked)}
                    name="isDebt"
                  />
                }
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <AccountBalance sx={{ mr: 1 }} />
                    <FormattedMessage id="isDebt" />
                  </Box>
                }
              />
              {isDebt && (
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="debt-from-label"><FormattedMessage id="debtFrom" /></InputLabel>
                  <Select
                    labelId="debt-from-label"
                    label={intl.formatMessage({ id: 'debtFrom' })}
                    value={debtFrom}
                    onChange={handleDebtFromChange}
                    required={isDebt}
                  >
                    <MenuItem value=""><FormattedMessage id="selectPerson" /></MenuItem>
                    {payers.filter(p => p !== expense.payer).map(payer => (
                      <MenuItem key={payer} value={payer}>{payer}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </FormControl>
          </Box>

          <Box>
            <Input
              type="file"
              onChange={handleFileChange}
              style={{ display: 'none' }}
              id="receipt-file-input"
            />
            <label htmlFor="receipt-file-input">
              <Button
                variant="outlined"
                component="span"
                startIcon={<Attachment />}
              >
                <FormattedMessage id="attachReceipt" />
              </Button>
            </label>
            {selectedFile && <Typography variant="caption" sx={{ ml: 2 }}>{selectedFile.name}</Typography>}
          </Box>

          <Button type="submit" variant="contained" color="primary" startIcon={<Save />} disabled={isSubmitting || uploading}>
            {isSubmitting ? intl.formatMessage({ id: 'submitting' }) : intl.formatMessage({ id: 'addExpense' })}
          </Button>
        </Box>

        <Dialog open={openCategoryDialog} onClose={handleCloseCategoryDialog}>
          <DialogTitle><FormattedMessage id="manageCategories" /></DialogTitle>
          <DialogContent>
            <List>
              {sortedCategories.map((category) => (
                <ListItem key={category}>
                  {editingCategoryName === category ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}>
                      <TextField
                        value={newCategoryName}
                        onChange={(e) => setNewCategoryName(e.target.value)}
                        fullWidth
                      />
                      <IconButton onClick={handleEditCategoryConfirm}>
                        <Save />
                      </IconButton>
                      <IconButton onClick={() => setEditingCategoryName('')}>
                        <Cancel />
                      </IconButton>
                    </Box>
                  ) : (
                    <>
                      <ListItemText primary={category} />
                      <IconButton onClick={() => handleEditCategoryStart(category)}>
                        <Edit />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteCategoryConfirm(category)}>
                        <Delete />
                      </IconButton>
                    </>
                  )}
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCategoryDialog}><FormattedMessage id="close" /></Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openPayerDialog} onClose={handleClosePayerDialog}>
          <DialogTitle><FormattedMessage id="managePayers" /></DialogTitle>
          <DialogContent>
            <List>
              {sortedPayers.map((payer) => (
                <ListItem key={payer}>
                  {editingPayerName === payer ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}>
                      <TextField
                        value={newPayerName}
                        onChange={(e) => setNewPayerName(e.target.value)}
                        fullWidth
                      />
                      <IconButton onClick={handleEditPayerConfirm}>
                        <Save />
                      </IconButton>
                      <IconButton onClick={() => setEditingPayerName('')}>
                        <Cancel />
                      </IconButton>
                    </Box>
                  ) : (
                    <>
                      <ListItemText primary={payer} />
                      <IconButton onClick={() => handleEditPayerStart(payer)}>
                        <Edit />
                      </IconButton>
                      <IconButton onClick={() => handleDeletePayerConfirm(payer)}>
                        <Delete />
                      </IconButton>
                      <IconButton onClick={() => handleOpenColorPicker(payer)}>
                        <div style={{ width: 20, height: 20, backgroundColor: payerColors[payer] || '#000000', borderRadius: '50%' }} />
                      </IconButton>
                    </>
                  )}
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClosePayerDialog}><FormattedMessage id="close" /></Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openColorPicker} onClose={handleCloseColorPicker}>
          <DialogTitle><FormattedMessage id="selectColor" /></DialogTitle>
          <DialogContent>
            <ChromePicker color={selectedColor} onChange={handleColorChange} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseColorPicker}><FormattedMessage id="cancel" /></Button>
            <Button onClick={handleColorChangeComplete}><FormattedMessage id="save" /></Button>
          </DialogActions>
        </Dialog>

        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
            <FormattedMessage id="expenseSubmittedSuccessfully" />
          </Alert>
        </Snackbar>
      </motion.div>
    </LocalizationProvider>
  );
};

export default ExpenseForm;