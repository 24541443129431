import React from 'react';
import { Dialog, DialogTitle, DialogContent, List, ListItem, ListItemIcon, ListItemText, Checkbox } from '@mui/material';
import { FormattedMessage } from 'react-intl';

interface WidgetSelectorProps {
  open: boolean;
  onClose: () => void;
  selectedWidgets: string[];
  onSelectionChange: (widgets: string[]) => void;
  availableWidgets: string[];
}

const WidgetSelector: React.FC<WidgetSelectorProps> = ({ 
  open, 
  onClose, 
  selectedWidgets, 
  onSelectionChange, 
  availableWidgets
}) => {
  const handleToggle = (value: string) => () => {
    const currentIndex = selectedWidgets.indexOf(value);
    const newChecked = [...selectedWidgets];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    onSelectionChange(newChecked);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle><FormattedMessage id="selectWidgets" /></DialogTitle>
      <DialogContent>
        <List>
          {availableWidgets.map((widgetId) => (
            <ListItem key={widgetId} dense button onClick={handleToggle(widgetId)}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selectedWidgets.includes(widgetId)}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary={<FormattedMessage id={widgetId} />} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default WidgetSelector;