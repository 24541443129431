import React, { useState, useEffect, useMemo } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  IconButton, Grid, useMediaQuery, Theme, Button, Card, CardContent, Typography,
  Box, Container, Tabs, Tab, Avatar, TextField, InputAdornment, Tooltip,
  Fab, Dialog, DialogContent, DialogTitle, CircularProgress
} from '@mui/material';
import { Edit, Delete, Add, List, ShowChart, TrendingUp, AccountBalance, MonetizationOn } from '@mui/icons-material';
import { useIntl, FormattedMessage } from 'react-intl';
import { Investment } from '../types/Investment';
import { getInvestments, deleteInvestment, addInvestment, updateInvestment, getCurrentTokenPrices } from '../firebase/firestoreOperations';
import InvestmentForm from './InvestmentForm';
import { User } from 'firebase/auth';
import { getMultipleCryptoPrices, getTokenDetails } from '../services/coingeckoService';
import { ArrowDropUp, ArrowDropDown } from '@mui/icons-material';
import { auth } from '../firebase/config';
import { TokenDetails } from '../services/coingeckoService';
import { styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import AddIcon from '@mui/icons-material/Add';

interface PriceData {
  price: number;
  price_change_percentage_24h: number;
}

interface InvestmentListProps {
  user: User;
}

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    width: '120px',
  },
}));

const InvestmentList: React.FC<InvestmentListProps> = ({ user }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [investments, setInvestments] = useState<Investment[]>([]);
  const [editingInvestment, setEditingInvestment] = useState<Investment | undefined>(undefined);
  const [currentPrices, setCurrentPrices] = useState<{[key: string]: PriceData}>({});
  const [tabValue, setTabValue] = useState(0);
  const intl = useIntl();
  const [tokenDetails, setTokenDetails] = useState<{[key: string]: TokenDetails}>({});
  const [forecastPrices, setForecastPrices] = useState<{[key: string]: string}>({});
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getTokenId = (token: string): string => {
    switch (token.toLowerCase()) {
      case 'ada':
        return 'cardano';
      case 'eth':
        return 'ethereum';
      default:
        return token.toLowerCase();
    }
  };

  const fetchInvestments = async () => {
    if (user) {
      try {
        console.log('Fetching investments for user:', user.uid);
        const fetchedInvestments = await getInvestments(user.uid);
        console.log('Fetched investments:', fetchedInvestments);
        setInvestments(fetchedInvestments);
        
        const tokens = fetchedInvestments.map(inv => inv.token);
        try {
          console.log('Fetching prices for tokens:', tokens);
          const prices = await getMultipleCryptoPrices(tokens);
          console.log('Received prices:', prices);
          setCurrentPrices(prices as { [key: string]: PriceData });

          // Fetch token details including images
          const details = await Promise.all(tokens.map(token => getTokenDetails(token)));
          console.log('Received token details:', details);
          const detailsMap = details.reduce((acc: {[key: string]: TokenDetails}, detail: TokenDetails | null) => {
            if (detail) {
              acc[detail.symbol] = detail;
            }
            return acc;
          }, {});
          console.log('Token details map:', detailsMap);
          setTokenDetails(detailsMap);
        } catch (error) {
          console.error('Error fetching current prices or token details:', error);
        }
      } catch (error) {
        console.error('Error fetching investments:', error);
      }
    }
  };

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      await fetchInvestments();
      // Refresh prices every 60 seconds
      const intervalId = setInterval(fetchInvestments, 60000);
      return () => clearInterval(intervalId);
      setIsLoading(false);
    };

    loadData();
  }, [user]);

  const handleEdit = (investment: Investment) => {
    setEditingInvestment(investment);
    setIsEditFormOpen(true);
  };

  const handleDelete = async (id: string) => {
    if (window.confirm(intl.formatMessage({ id: 'confirmDeleteInvestment' }))) {
      try {
        await deleteInvestment(id);
        await fetchInvestments(); // Refresh the list after deletion
      } catch (error) {
        console.error('Error deleting investment:', error);
      }
    }
  };

  const handleOpenForm = () => {
    setIsFormOpen(true);
    setEditingInvestment(undefined);
  };

  const handleCloseForm = () => {
    setIsFormOpen(false);
    setEditingInvestment(undefined);
  };

  const handleCloseEditForm = () => {
    setIsEditFormOpen(false);
    setEditingInvestment(undefined);
  };

  const handleAddInvestment = async (investment: Omit<Investment, 'id'>) => {
    if (!auth.currentUser) {
      console.error("User is not authenticated");
      return;
    }

    try {
      console.log('Adding investment:', investment);
      console.log('Current user UID:', auth.currentUser.uid);

      // Fetch token details and current price from CoinGecko
      const tokenId = getTokenId(investment.token);
      const [tokenDetail, priceData] = await Promise.all([
        getTokenDetails(tokenId),
        getMultipleCryptoPrices([tokenId])
      ]);

      if (tokenDetail) {
        setTokenDetails(prevDetails => ({
          ...prevDetails,
          [tokenId]: tokenDetail
        }));
      }

      if (priceData && priceData[tokenId]) {
        setCurrentPrices(prevPrices => ({
          ...prevPrices,
          [tokenId]: priceData[tokenId] as PriceData
        }));
      }

      await addInvestment(auth.currentUser.uid, investment);
      console.log('Investment added successfully');
      await fetchInvestments();
      setEditingInvestment(undefined);
      setTabValue(1);
      handleCloseForm();
    } catch (error) {
      console.error('Error adding investment:', error);
    }
  };

  const handleUpdateInvestment = async (id: string, investment: Partial<Investment>) => {
    try {
      await updateInvestment(id, investment);
      await fetchInvestments(); // Update the list of investments
      setEditingInvestment(undefined);
      setIsEditFormOpen(false);
    } catch (error) {
      console.error('Error updating investment:', error);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const formatPrice = (token: string, priceData: PriceData | undefined) => {
    if (!priceData || priceData.price === undefined) return 'N/A';
    if (token.toUpperCase() === 'ADA') {
      return `€ ${priceData.price.toFixed(4)}`;
    }
    return `€ ${priceData.price.toFixed(2)}`;
  };

  const renderPriceChange = (priceData: PriceData | undefined) => {
    if (!priceData || priceData.price_change_percentage_24h === undefined) return null;
    const priceChange = priceData.price_change_percentage_24h;
    const color = priceChange >= 0 ? 'success.main' : 'error.main';
    const Icon = priceChange >= 0 ? ArrowDropUp : ArrowDropDown;
    return (
      <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center', ml: 1, color }}>
        <Icon />
        {Math.abs(priceChange).toFixed(2)}%
      </Box>
    );
  };

  const portfolioSummary = useMemo(() => {
    let totalInvestment = 0;
    let currentValue = 0;
    
    investments.forEach(investment => {
      const priceData = currentPrices[investment.token.toUpperCase()];
      
      totalInvestment += parseFloat(investment.investment);
      if (priceData) {
        currentValue += priceData.price * Number(investment.quantity);
      }
    });

    const profitLoss = currentValue - totalInvestment;
    const profitLossPercentage = (profitLoss / totalInvestment) * 100;

    return {
      totalInvestment,
      currentValue,
      profitLoss,
      profitLossPercentage
    };
  }, [investments, currentPrices]);

  const formatPurchasePrice = (token: string, price: number) => {
    return token.toUpperCase() === 'ADA' ? price.toFixed(4) : price.toFixed(2);
  };

  const renderTokenWithLogo = (token: string) => {
    const details = tokenDetails[token.toUpperCase()];
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {details && details.image ? (
          <Avatar src={details.image} alt={token} sx={{ width: 24, height: 24, mr: 1 }} />
        ) : (
          <Avatar sx={{ width: 24, height: 24, mr: 1 }}>{token.charAt(0)}</Avatar>
        )}
        <Typography variant="body2">{token}</Typography>
      </Box>
    );
  };

  const renderCard = (title: string, content: React.ReactNode, icon: React.ReactNode, infoTooltip: string) => (
    <Card sx={{ mb: 2, boxShadow: 3, borderRadius: 2, overflow: 'hidden' }}>
      <Box sx={{ 
        backgroundColor: 'primary.main', 
        color: 'primary.contrastText', 
        p: 1.5, 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {icon}
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', ml: 1 }}>
            {title}
          </Typography>
        </Box>
        <Tooltip title={infoTooltip}>
          <InfoIcon fontSize="small" />
        </Tooltip>
      </Box>
      <CardContent sx={{ p: 1.5 }}>
        {content}
      </CardContent>
    </Card>
  );

  const renderMobileCard = (title: string, content: React.ReactNode, icon: React.ReactNode, infoTooltip: string) => (
    <Card sx={{ mb: 2, boxShadow: 3, borderRadius: 2, overflow: 'hidden', width: '100%' }}>
      <Box sx={{ 
        backgroundColor: 'primary.main', 
        color: 'primary.contrastText', 
        p: 1.5, 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {icon}
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', ml: 1 }}>
            {title}
          </Typography>
        </Box>
        <Tooltip title={infoTooltip}>
          <InfoIcon fontSize="small" />
        </Tooltip>
      </Box>
      <CardContent sx={{ p: 1.5, maxHeight: 'calc(100vh - 200px)', overflow: 'auto' }}>
        {content}
      </CardContent>
    </Card>
  );

  const renderMobileOverview = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
      {renderMobilePortfolioSummary()}
      {renderMobileCurrentPrices()}
      {renderMobileTotalsByToken()}
      {renderMobileForecastSimulation()}
    </Box>
  );

  const renderMobilePortfolioSummary = () => renderMobileCard(
    intl.formatMessage({ id: "portfolioSummary" }),
    <Grid container spacing={1} justifyContent="center">
      {[
        { icon: <AccountBalance fontSize="small" />, label: "totalInvestment", value: `€${Math.floor(portfolioSummary.totalInvestment).toLocaleString()}` },
        { icon: <MonetizationOn fontSize="small" />, label: "currentValue", value: `€${Math.floor(portfolioSummary.currentValue).toLocaleString()}` },
        { icon: <TrendingUp fontSize="small" />, label: "profitLoss", value: `€${portfolioSummary.profitLoss.toFixed(2).toLocaleString()} (${portfolioSummary.profitLossPercentage.toFixed(2)}%)` },
      ].map((item, index) => (
        <Grid item xs={4} key={index}>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            textAlign: 'center',
            p: 1,
            borderRadius: 1,
            backgroundColor: 'background.paper',
            boxShadow: 1
          }}>
            {item.icon}
            <Typography variant="caption" color="text.secondary">
              <FormattedMessage id={item.label} />
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 'bold', color: item.label === "profitLoss" ? (portfolioSummary.profitLoss >= 0 ? 'success.main' : 'error.main') : 'inherit' }}>
              {item.value}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>,
    <AccountBalance fontSize="small" />,
    intl.formatMessage({ id: "portfolioSummaryInfo" })
  );

  const renderMobileCurrentPrices = () => {
    const uniqueTokens = Array.from(new Set(investments.map(inv => inv.token)));
    return renderMobileCard(
      intl.formatMessage({ id: "currentPrices" }),
      <Grid container spacing={1}>
        {uniqueTokens.map((token) => {
          const priceData = currentPrices[token.toUpperCase()];
          return (
            <Grid item xs={6} key={token}>
              <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                p: 1, 
                borderRadius: 1, 
                backgroundColor: 'background.paper',
                boxShadow: 1
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
                  {renderTokenWithLogo(token)}
                </Box>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                  {formatPrice(token, priceData)}
                </Typography>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  color: priceData && priceData.price_change_percentage_24h >= 0 ? 'success.main' : 'error.main'
                }}>
                  {renderPriceChange(priceData)}
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>,
      <ShowChart fontSize="small" />,
      intl.formatMessage({ id: "currentPricesInfo" })
    );
  };

  const renderMobileTotalsByToken = () => {
    const totals = calculateTotalsByToken();
    return renderMobileCard(
      intl.formatMessage({ id: "totalsByToken" }),
      <TableContainer component={Paper} sx={{ maxHeight: 300, overflow: 'auto' }}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell><FormattedMessage id="token" /></TableCell>
              <TableCell><FormattedMessage id="totalQuantity" /></TableCell>
              <TableCell><FormattedMessage id="currentValue" /></TableCell>
              <TableCell><FormattedMessage id="profitLoss" /></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(totals).map(([token, data]) => {
              const currentPrice = currentPrices[token.toUpperCase()]?.price || 0;
              const currentValue = Math.floor(data.quantity * currentPrice);
              const profitLoss = Math.floor(currentValue - data.investment);
              const profitLossPercentage = (profitLoss / data.investment) * 100;
              return (
                <TableRow key={token}>
                  <TableCell>{renderTokenWithLogo(token)}</TableCell>
                  <TableCell>{formatQuantity(token, data.quantity)}</TableCell>
                  <TableCell>{currentValue} €</TableCell>
                  <TableCell>
                    <Box sx={{ 
                      color: profitLoss >= 0 ? 'success.main' : 'error.main',
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                      {profitLoss >= 0 ? <ArrowDropUp fontSize="small" /> : <ArrowDropDown fontSize="small" />}
                      <Typography variant="caption">
                        {profitLoss} € ({profitLossPercentage.toFixed(2)}%)
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>,
      <List fontSize="small" />,
      intl.formatMessage({ id: "portfolioInfo" })
    );
  };

  const renderMobileForecastSimulation = () => renderMobileCard(
    intl.formatMessage({ id: "forecastSimulation" }),
    <TableContainer component={Paper} sx={{ maxHeight: 300, overflow: 'auto' }}>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell><FormattedMessage id="token" /></TableCell>
            <TableCell><FormattedMessage id="forecastPrice" /></TableCell>
            <TableCell><FormattedMessage id="forecastValue" /></TableCell>
            <TableCell><FormattedMessage id="potentialProfit" /></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(calculateTotalsByToken()).map(([token, data]) => {
            const forecastPrice = parseFloat(forecastPrices[token] || '0');
            const forecastValue = Math.floor(data.quantity * forecastPrice);
            const potentialProfit = Math.floor(forecastValue - data.investment);
            const potentialProfitPercentage = (potentialProfit / data.investment) * 100;
            return (
              <TableRow key={token}>
                <TableCell>{renderTokenWithLogo(token)}</TableCell>
                <TableCell>
                  <StyledTextField
                    size="small"
                    type="number"
                    inputMode="decimal"
                    value={forecastPrices[token] || ''}
                    onChange={(e) => handleForecastPriceChange(token, e.target.value)}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">€</InputAdornment>,
                    }}
                    sx={{ '& .MuiOutlinedInput-root': { borderRadius: 4 } }}
                  />
                </TableCell>
                <TableCell>{forecastValue} €</TableCell>
                <TableCell>
                  <Box sx={{ 
                    color: potentialProfit >= 0 ? 'success.main' : 'error.main',
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                    {potentialProfit >= 0 ? <ArrowDropUp fontSize="small" /> : <ArrowDropDown fontSize="small" />}
                    <Typography variant="caption">
                      {potentialProfit} € ({potentialProfitPercentage.toFixed(2)}%)
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>,
    <TrendingUp fontSize="small" />,
    intl.formatMessage({ id: "forecastSimulationInfo" })
  );

  const renderMobileInvestmentList = () => renderMobileCard(
    intl.formatMessage({ id: "investmentList" }),
    <TableContainer component={Paper} sx={{ maxHeight: 'calc(100vh - 200px)', overflow: 'auto' }}>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell><FormattedMessage id="token" /></TableCell>
            <TableCell><FormattedMessage id="investment" /></TableCell>
            <TableCell><FormattedMessage id="quantity" /></TableCell>
            <TableCell><FormattedMessage id="purchasePrice" /></TableCell>
            <TableCell><FormattedMessage id="currentValue" /></TableCell>
            <TableCell><FormattedMessage id="actions" /></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {investments.map((investment) => {
            const priceData = currentPrices[investment.token.toUpperCase()];
            const currentPrice = priceData ? priceData.price : 0;
            const currentValue = Math.floor(currentPrice * Number(investment.quantity));
            return (
              <TableRow key={investment.id}>
                <TableCell>{renderTokenWithLogo(investment.token)}</TableCell>
                <TableCell>{Math.floor(parseFloat(investment.investment))} €</TableCell>
                <TableCell>{formatQuantity(investment.token, Number(investment.quantity))}</TableCell>
                <TableCell>{formatPurchasePrice(investment.token, investment.purchasePrice)} €</TableCell>
                <TableCell>{currentValue} €</TableCell>
                <TableCell>
                  <IconButton size="small" onClick={() => handleEdit(investment)}>
                    <Edit fontSize="small" />
                  </IconButton>
                  <IconButton size="small" onClick={() => handleDelete(investment.id)}>
                    <Delete fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>,
    <List fontSize="small" />,
    intl.formatMessage({ id: "investmentListInfo" })
  );

  const renderCurrentPrices = () => {
    const uniqueTokens = Array.from(new Set(investments.map(inv => inv.token)));
    return renderCard(
      intl.formatMessage({ id: "currentPrices" }),
      <Grid container spacing={2}>
        {uniqueTokens.map((token) => {
          const priceData = currentPrices[token.toUpperCase()];
          return (
            <Grid item xs={12} sm={6} md={4} key={token}>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                p: 2, 
                borderRadius: 2, 
                backgroundColor: 'background.paper',
                boxShadow: 1
              }}>
                <Box sx={{ mr: 2, minWidth: 40 }}>
                  {renderTokenWithLogo(token)}
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    {formatPrice(token, priceData)}
                  </Typography>
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    color: priceData && priceData.price_change_percentage_24h >= 0 ? 'success.main' : 'error.main'
                  }}>
                    {priceData && priceData.price_change_percentage_24h !== undefined && (
                      <>
                        {priceData.price_change_percentage_24h >= 0 ? <ArrowDropUp fontSize="small" /> : <ArrowDropDown fontSize="small" />}
                        <Typography variant="body2">
                          {Math.abs(priceData.price_change_percentage_24h).toFixed(2)}%
                        </Typography>
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>,
      <ShowChart fontSize="small" />,
      intl.formatMessage({ id: "currentPricesInfo" })
    );
  };

  const formatQuantity = (token: string, quantity: number) => {
    return token.toUpperCase() === 'ADA' ? quantity.toFixed(2) : quantity.toString();
  };

  const calculateTotalsByToken = () => {
    const totals: { [key: string]: { quantity: number, investment: number } } = {};
    investments.forEach((investment) => {
      if (!totals[investment.token]) {
        totals[investment.token] = { quantity: 0, investment: 0 };
      }
      totals[investment.token].quantity += Number(investment.quantity);
      totals[investment.token].investment += parseFloat(investment.investment);
    });
    return totals;
  };

  const renderTotalsByToken = () => {
    const totals = calculateTotalsByToken();
    return renderCard(
      intl.formatMessage({ id: "totalsByToken" }),
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow sx={{ backgroundColor: 'background.default' }}>
              <TableCell sx={{ fontWeight: 'bold' }}><FormattedMessage id="token" /></TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}><FormattedMessage id="totalQuantity" /></TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}><FormattedMessage id="totalInvestment" /></TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}><FormattedMessage id="averagePurchasePrice" /></TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}><FormattedMessage id="currentPrice" /></TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}><FormattedMessage id="currentValue" /></TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}><FormattedMessage id="profitLoss" /></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(totals).map(([token, data]) => {
              const currentPrice = currentPrices[token.toUpperCase()]?.price || 0;
              const currentValue = Math.floor(data.quantity * currentPrice);
              const profitLoss = Math.floor(currentValue - data.investment);
              const profitLossPercentage = (profitLoss / data.investment) * 100;
              const averagePurchasePrice = data.investment / data.quantity;
              return (
                <TableRow key={token} sx={{ '&:nth-of-type(odd)': { backgroundColor: 'action.hover' } }}>
                  <TableCell>{renderTokenWithLogo(token)}</TableCell>
                  <TableCell>{formatQuantity(token, data.quantity)}</TableCell>
                  <TableCell>{Math.floor(data.investment)} €</TableCell>
                  <TableCell>
                    {token.toUpperCase() === 'ADA' 
                      ? averagePurchasePrice.toFixed(4) 
                      : averagePurchasePrice.toFixed(2)} €
                  </TableCell>
                  <TableCell>{formatPrice(token, currentPrices[token.toUpperCase()])}</TableCell>
                  <TableCell>{currentValue} €</TableCell>
                  <TableCell>
                    <Box sx={{ 
                      color: profitLoss >= 0 ? 'success.main' : 'error.main',
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                      {profitLoss >= 0 ? <ArrowDropUp fontSize="small" /> : <ArrowDropDown fontSize="small" />}
                      <Typography variant="body2">
                        {profitLoss} € ({profitLossPercentage.toFixed(2)}%)
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>,
      <List fontSize="small" />,
      intl.formatMessage({ id: "portfolioInfo" })
    );
  };

  const renderPortfolioSummary = () => renderCard(
    intl.formatMessage({ id: "portfolioSummary" }),
    <Grid container spacing={3}>
      {[
        { icon: <AccountBalance sx={{ fontSize: 40, color: 'primary.main', mb: 1 }} />, label: "totalInvestment", value: `€${Math.floor(portfolioSummary.totalInvestment).toLocaleString()}` },
        { icon: <MonetizationOn sx={{ fontSize: 40, color: 'primary.main', mb: 1 }} />, label: "currentValue", value: `€${Math.floor(portfolioSummary.currentValue).toLocaleString()}` },
        { icon: <TrendingUp sx={{ fontSize: 40, color: 'primary.main', mb: 1 }} />, label: "profitLoss", value: `€${portfolioSummary.profitLoss.toFixed(2).toLocaleString()} (${portfolioSummary.profitLossPercentage.toFixed(2)}%)` },
      ].map((item, index) => (
        <Grid item xs={12} sm={4} key={index}>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            textAlign: 'center',
            p: 2,
            borderRadius: 2,
            backgroundColor: 'background.paper',
            boxShadow: 1
          }}>
            {item.icon}
            <Typography variant="body2" color="text.secondary" gutterBottom>
              <FormattedMessage id={item.label} />
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: item.label === "profitLoss" ? (portfolioSummary.profitLoss >= 0 ? 'success.main' : 'error.main') : 'inherit' }}>
              {item.value}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>,
    <AccountBalance fontSize="small" />,
    intl.formatMessage({ id: "portfolioSummaryInfo" })
  );

  const handleForecastPriceChange = (token: string, value: string) => {
    let price = value.replace(',', '.');
    if (token.toUpperCase() === 'ADA') {
      // Limit to 4 decimal places for ADA
      price = price.match(/^\d*\.?\d{0,4}$/)?.[0] || '';
    } else {
      // Limit to 2 decimal places for other tokens
      price = price.match(/^\d*\.?\d{0,2}$/)?.[0] || '';
    }
    setForecastPrices(prev => ({ ...prev, [token]: price }));
  };

  const renderForecastSimulation = () => {
    const totals = calculateTotalsByToken();
    return renderCard(
      intl.formatMessage({ id: "forecastSimulation" }),
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow sx={{ backgroundColor: 'background.default' }}>
              <TableCell sx={{ fontWeight: 'bold' }}><FormattedMessage id="token" /></TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}><FormattedMessage id="totalQuantity" /></TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}><FormattedMessage id="forecastPrice" /></TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}><FormattedMessage id="forecastValue" /></TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}><FormattedMessage id="potentialProfit" /></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(totals).map(([token, data]) => {
              const forecastPrice = parseFloat(forecastPrices[token] || '0');
              const forecastValue = Math.floor(data.quantity * forecastPrice);
              const potentialProfit = Math.floor(forecastValue - data.investment);
              const potentialProfitPercentage = (potentialProfit / data.investment) * 100;
              return (
                <TableRow key={token} sx={{ '&:nth-of-type(odd)': { backgroundColor: 'action.hover' } }}>
                  <TableCell>{renderTokenWithLogo(token)}</TableCell>
                  <TableCell>{formatQuantity(token, data.quantity)}</TableCell>
                  <TableCell>
                    <StyledTextField
                      size="small"
                      type="number"
                      inputMode="decimal"
                      value={forecastPrices[token] || ''}
                      onChange={(e) => handleForecastPriceChange(token, e.target.value)}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                      }}
                      sx={{ '& .MuiOutlinedInput-root': { borderRadius: 4 } }}
                    />
                  </TableCell>
                  <TableCell>{forecastValue} €</TableCell>
                  <TableCell>
                    <Box sx={{ 
                      color: potentialProfit >= 0 ? 'success.main' : 'error.main',
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                      {potentialProfit >= 0 ? <ArrowDropUp fontSize="small" /> : <ArrowDropDown fontSize="small" />}
                      <Typography variant="body2">
                        {potentialProfit} € ({potentialProfitPercentage.toFixed(2)}%)
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>,
      <TrendingUp fontSize="small" />,
      intl.formatMessage({ id: "forecastSimulationInfo" })
    );
  };

  const renderInvestmentList = () => renderCard(
    intl.formatMessage({ id: "investmentList" }),
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell><FormattedMessage id="token" /></TableCell>
            <TableCell><FormattedMessage id="investment" /></TableCell>
            <TableCell><FormattedMessage id="quantity" /></TableCell>
            <TableCell><FormattedMessage id="purchasePrice" /></TableCell>
            <TableCell><FormattedMessage id="currentValue" /></TableCell>
            <TableCell><FormattedMessage id="profitLoss" /></TableCell>
            <TableCell><FormattedMessage id="actions" /></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {investments.map((investment) => {
            const priceData = currentPrices[investment.token.toUpperCase()];
            const currentPrice = priceData ? priceData.price : 0;
            const currentValue = Math.floor(currentPrice * Number(investment.quantity));
            const profitLoss = Math.floor(currentValue - parseFloat(investment.investment));
            const profitLossPercentage = (profitLoss / parseFloat(investment.investment)) * 100;

            return (
              <TableRow key={investment.id}>
                <TableCell>{renderTokenWithLogo(investment.token)}</TableCell>
                <TableCell>{Math.floor(parseFloat(investment.investment))} €</TableCell>
                <TableCell>{formatQuantity(investment.token, Number(investment.quantity))}</TableCell>
                <TableCell>{formatPurchasePrice(investment.token, investment.purchasePrice)} €</TableCell>
                <TableCell>{currentValue} €</TableCell>
                <TableCell>
                  <Box sx={{ 
                    color: profitLoss >= 0 ? 'success.main' : 'error.main',
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                    {profitLoss >= 0 ? <ArrowDropUp fontSize="small" /> : <ArrowDropDown fontSize="small" />}
                    <Typography variant="body2">
                      {profitLoss} € ({profitLossPercentage.toFixed(2)}%)
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <IconButton size="small" onClick={() => handleEdit(investment)}>
                    <Edit fontSize="small" />
                  </IconButton>
                  <IconButton size="small" onClick={() => handleDelete(investment.id)}>
                    <Delete fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>,
    <List fontSize="small" />,
    intl.formatMessage({ id: "investmentListInfo" })
  );

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      await fetchInvestments();
      setIsLoading(false);
    };

    loadData();
  }, [user]);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" disableGutters={isMobile} sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      {isMobile ? (
        <>
          <Box sx={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: 'background.paper' }}>
            <Tabs 
              value={tabValue} 
              onChange={handleTabChange} 
              variant="fullWidth" 
              sx={{ 
                mb: 2, 
                borderBottom: 1, 
                borderColor: 'divider',
                '& .MuiTab-root': { minHeight: '48px' }
              }}
            >
              <Tab icon={<ShowChart />} label={<FormattedMessage id="overview" />} />
              <Tab icon={<List />} label={<FormattedMessage id="details" />} />
            </Tabs>
          </Box>
          <Box sx={{ flexGrow: 1, overflow: 'auto', px: 1, pb: 2 }}>
            {tabValue === 0 && (
              <>
                {renderMobilePortfolioSummary()}
                {renderMobileCurrentPrices()}
                {renderMobileTotalsByToken()}
                {renderMobileForecastSimulation()}
              </>
            )}
            {tabValue === 1 && (
              <>
                {renderMobileInvestmentList()}
              </>
            )}
          </Box>
        </>
      ) : (
        <Box sx={{ my: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {renderPortfolioSummary()}
              {renderCurrentPrices()}
              {renderTotalsByToken()}
              {renderForecastSimulation()}
              {renderInvestmentList()}
            </Grid>
          </Grid>
        </Box>
      )}
      <Fab
        color="primary"
        aria-label="add"
        onClick={handleOpenForm}
        size="small"
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
        }}
      >
        <AddIcon fontSize="small" />
      </Fab>
      <Dialog open={isFormOpen} onClose={handleCloseForm} fullWidth maxWidth="sm">
        <DialogTitle>
          <FormattedMessage id="addInvestment" />
        </DialogTitle>
        <DialogContent>
          <InvestmentForm
            onAddInvestment={handleAddInvestment}
            onUpdateInvestment={handleUpdateInvestment}
            userId={user.uid}
            onCancel={handleCloseForm}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={isEditFormOpen} onClose={handleCloseEditForm} fullWidth maxWidth="sm">
        <DialogTitle>
          <FormattedMessage id="editInvestment" />
        </DialogTitle>
        <DialogContent>
          <InvestmentForm
            onAddInvestment={handleAddInvestment}
            onUpdateInvestment={handleUpdateInvestment}
            investment={editingInvestment}
            userId={user.uid}
            onCancel={handleCloseEditForm}
          />
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default InvestmentList;