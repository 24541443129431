import axios from 'axios';

const API_BASE_URL = 'https://api.coingecko.com/api/v3';
const API_KEY = 'CG-VFzjfLVB22BbHDxN3rFwayDe';

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'x-cg-demo-api-key': API_KEY
  }
});

export const pingCoinGecko = async (): Promise<boolean> => {
  try {
    const response = await axiosInstance.get('/ping');
    return response.data.gecko_says === '(V3) To the Moon!';
  } catch (error) {
    console.error('Error pinging CoinGecko API:', error);
    return false;
  }
};

export const getCryptoPrice = async (cryptoId: string, currency: string = 'usd'): Promise<number | null> => {
  try {
    const response = await axiosInstance.get('/simple/price', {
      params: {
        ids: cryptoId,
        vs_currencies: currency
      }
    });
    return response.data[cryptoId][currency];
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(`Error fetching price for ${cryptoId}:`, error.response?.data || error.message);
    } else {
      console.error(`Error fetching price for ${cryptoId}:`, String(error));
    }
    return null;
  }
};

export const searchCrypto = async (query: string): Promise<any[]> => {
  try {
    const response = await axiosInstance.get('/search', {
      params: { query }
    });
    return response.data.coins;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(`Error searching for "${query}":`, error.response?.data || error.message);
    } else {
      console.error(`Error searching for "${query}":`, String(error));
    }
    return [];
  }
};

export const getMultipleCryptoPrices = async (symbols: string[]): Promise<{[key: string]: { price: number, price_change_percentage_24h: number }}> => {
  try {
    console.log('Fetching prices for:', symbols);
    
    // First, search for the coins to get their IDs
    const searchPromises = symbols.map(symbol => searchCrypto(symbol));
    const searchResults = await Promise.all(searchPromises);
    
    const coinIds = searchResults.map(result => result[0]?.id).filter(Boolean);
    
    if (coinIds.length === 0) {
      throw new Error('No valid coin IDs found');
    }
    
    const response = await axiosInstance.get('/simple/price', {
      params: {
        ids: coinIds.join(','),
        vs_currencies: 'eur',
        include_24hr_change: true
      }
    });
    
    console.log('API response:', response.data);
    
    const prices: {[key: string]: { price: number, price_change_percentage_24h: number }} = {};
    symbols.forEach((symbol, index) => {
      const coinId = coinIds[index];
      const coinData = response.data[coinId];
      if (coinData) {
        prices[symbol.toUpperCase()] = {
          price: coinData.eur,
          price_change_percentage_24h: coinData.eur_24h_change
        };
      }
    });
    
    console.log('Processed prices:', prices);
    return prices;
  } catch (error) {
    console.error('Error fetching multiple crypto prices:', error);
    throw error;
  }
};

export interface TokenDetails {
  id: string;
  symbol: string;
  name: string;
  image: string;
  current_price: number;
  price_change_percentage_24h: number;
}

export const getTokenDetails = async (symbol: string): Promise<TokenDetails | null> => {
  try {
    // First, search for the coin to get its ID
    const searchResult = await searchCrypto(symbol);
    if (searchResult.length === 0) {
      throw new Error(`No results found for symbol: ${symbol}`);
    }
    
    const coinId = searchResult[0].id;
    
    const response = await axiosInstance.get(`/coins/${coinId}`, {
      params: {
        localization: false,
        tickers: false,
        market_data: true,
        community_data: false,
        developer_data: false,
        sparkline: false
      }
    });
    const data = response.data;
    return {
      id: data.id,
      symbol: data.symbol.toUpperCase(),
      name: data.name,
      image: data.image.small,
      current_price: data.market_data.current_price.eur,
      price_change_percentage_24h: data.market_data.price_change_percentage_24h
    };
  } catch (error) {
    console.error(`Error fetching details for token ${symbol}:`, error);
    return null;
  }
};