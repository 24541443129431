import React, { ReactNode } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar } from '@mui/material';
import { Dashboard, AddCircle, CalendarToday, History, BarChart, Settings } from '@mui/icons-material';
import WalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { FormattedMessage } from 'react-intl';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

interface SidebarProps {
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  menuItems: Array<{ id: string; text: string; icon: ReactNode; value: string; }>;
  setMenuItems: React.Dispatch<React.SetStateAction<Array<{ id: string; text: string; icon: ReactNode; value: string; }>>>;
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
  drawerWidth: number;
}

const Sidebar: React.FC<SidebarProps> = ({
  activeTab,
  setActiveTab,
  menuItems,
  setMenuItems,
  mobileOpen,
  handleDrawerToggle,
  drawerWidth
}) => {
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(menuItems);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setMenuItems(items);
    localStorage.setItem('menuOrder', JSON.stringify(items.map(item => item.id)));
  };

  const drawer = (
    <div>
      <Toolbar />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="menu">
          {(provided) => (
            <List {...provided.droppableProps} ref={provided.innerRef}>
              {menuItems.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <ListItem
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      button
                      onClick={() => setActiveTab(item.value)}
                      selected={activeTab === item.value}
                    >
                      <ListItemIcon>
                        {item.id === 'monthly' ? <CalendarToday /> :
                         item.id === 'income' ? <WalletIcon /> :
                         item.icon}
                      </ListItemIcon>
                      <ListItemText primary={<FormattedMessage id={`menu.${item.id}`} />} />
                    </ListItem>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );

  return (
    <>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open
      >
        {drawer}
      </Drawer>
    </>
  );
};

export default Sidebar;