import React from 'react';
import { Chip, Tooltip, ChipProps, useTheme } from '@mui/material';

interface CustomChipProps extends Omit<ChipProps, 'color'> {
  label: string;
  color?: string;
  tooltip?: string;
  payerColors?: { [key: string]: string };
}

const CustomChip: React.FC<CustomChipProps> = ({ label, color, tooltip, payerColors, ...props }) => {
  const theme = useTheme();
  
  // Determine the background color
  let backgroundColor: string;
  if (color) {
    backgroundColor = color;
  } else if (payerColors && payerColors[label]) {
    backgroundColor = payerColors[label];
  } else {
    backgroundColor = theme.palette.grey[300];
  }

  const textColor = theme.palette.getContrastText(backgroundColor);

  const chip = (
    <Chip
      label={label}
      sx={{
        backgroundColor,
        color: textColor,
        '&:hover': {
          backgroundColor: backgroundColor,
        },
        // Reduced size and consistent dimensions
        height: '24px',
        minWidth: '60px',
        maxWidth: '100px',
        fontSize: '0.75rem',
        fontWeight: 'medium',
        '& .MuiChip-label': {
          padding: '0 6px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      }}
      {...props}
    />
  );

  return tooltip ? <Tooltip title={tooltip}>{chip}</Tooltip> : chip;
};

export default CustomChip;